/**
 * Create an object from an array or object's key, value to new keys/values
 * @param {Array|object} arrayOrObject array or object to iterate over
 * @param {function(string|number, any, object) : [string|number, any]} func function that takes
 * in (key, value, object) and returns new [key, value] (as a two-item array)
 */
function mapKeyValues(arrayOrObject, func) {
  if (Array.isArray(arrayOrObject)) {
    return arrayOrObject.reduce((acc, item, index) => {
      const [key, value] = func(index, item, acc);
      acc[key] = value;
      return acc;
    }, {});
  }
  return Object.entries(arrayOrObject).reduce((acc, [key, value]) => {
    const [newKey, newValue] = func(key, value, acc);
    acc[newKey] = newValue;
    return acc;
  }, {});
}

export default mapKeyValues;
