/* eslint-disable no-console, no-underscore-dangle */
/**
 * Code adapted from:
 * https://github.com/Carriyo/browser-telemetry
 */
import { detect as detectBrowser } from 'detect-browser';

let browser;
let timeZone;
const screenResolution = {
  width: window.screen.width,
  height: window.screen.height,
};

try {
  browser = detectBrowser();
} catch (err) {
  browser = {};
}

try {
  const dateTimeFormat = new Intl.DateTimeFormat();
  timeZone = dateTimeFormat.resolvedOptions()?.timeZone;
} catch (err) {
  console.error(err);
}

async function defaultDispatcher(url, payload) {
  const blob = new Blob([JSON.stringify(payload)], {
    type: 'application/json',
  });
  return navigator.sendBeacon(url, blob);
}

/**
 * Default configuration
 */
const _DEFAULTS = {
  url: '/api/log',
  logLevels: ['error', 'warn'],
  dispatchPayload: defaultDispatcher,
};

function debounce(func, timeout = 100) {
  let timer;
  return function debounceInner() {
    clearTimeout(timer);
    timer = setTimeout(func, timeout);
  };
}

/**
 * Logger Class which exposes API for intercepting log, errors
 */
function Logger() {
  this.buffer = [];
  this.url = _DEFAULTS.url;
  this.logLevels = _DEFAULTS.logLevels;
  this.dispatchPayload = _DEFAULTS.dispatchPayload;
  this.delayedFlush = debounce(this.flush.bind(this));
}

/**
 * Init API for initializing the class with parameters.
 */
Logger.prototype.init = function init(options) {
  // eslint-disable-next-line no-param-reassign
  options = options || _DEFAULTS;
  this.url = options.url || this.url;
  this.logLevels = options.logLevels || this.logLevels;

  // Use dispatchPayload function to customize the exact network calls made for logging.
  // e.g. add authentication. This function must never throw any errors.
  this.dispatchPayload = options.dispatchPayload !== undefined
    ? options.dispatchPayload
    : this.dispatchPayload;

  // intercept console.error
  ['error'].forEach((level) => {
    const _fn = console[level];
    // named function in stack trace
    const levelLogger = (...args) => {
      // Do not auto log axios errors, rather log explicitly.
      // Besides, backend should already have logs for these
      if (!(args[0] instanceof Error) && !args[0]?.isAxiosError) {
        this.addToQ(level.toUpperCase(), args, false, false);
      }
      _fn.apply(console, args);
    };
    console[level] = levelLogger;
    console[`_${level}`] = _fn;
  });

  // On page reload or close or navigation, we want send any pending data
  document.addEventListener('visibilitychange', () => {
    if (document.visibilityState === 'hidden') {
      this.flush();
    }
  });
};

Logger.prototype.info = function info(...args) {
  this.addToQ('INFO', args);
};

Logger.prototype.debug = function debug(...args) {
  this.addToQ('DEBUG', args);
};

Logger.prototype.warn = function warn(...args) {
  this.addToQ('WARN', args);
};

Logger.prototype.error = function error(...args) {
  this.addToQ('ERROR', args);
};

Logger.prototype.critical = function warn(...args) {
  this.addToQ('ERROR', args, true);
};

const replacer = () => {
  const cache = new WeakSet();
  return (key, val) => {
    if (typeof val === 'object' && val !== null) {
      if (cache.has(val)) return '[Circular]';
      cache.add(val);
    }
    return val;
  };
};

const stringify = (input, indent) => {
  if (input === null || input === undefined) return undefined;
  return typeof input === 'object'
    ? JSON.stringify(input, replacer(), indent)
    : String(input);
};

function toNumber(val) {
  const num = parseInt(val, 10);
  return Number.isFinite(num) ? num : undefined;
}

/**
 * Adds message and type to Queue
 */
Logger.prototype.addToQ = function addToQ(
  type,
  args,
  unexpectedError = false,
  logToConsole = true,
) {
  try {
    if (logToConsole) {
      const lowerType = type.toLowerCase();
      // DO NOT log with console.error, to prevent infinite cyclic logging
      (console[`_${lowerType}`] || console[lowerType])(...args);
    }
    if (this.logLevels.includes(type.toLowerCase())) {
      let logObject = args?.[0]; // currently we only care about first log argument
      // Vite/CRA/browser extensions can log errors in all sorts of formats like:
      // console.error('message %c', 'string', error)
      // We might have to find a way to serialize it properly
      if (typeof logObject === 'string') {
        logObject = { message: logObject };
      } else if (logObject instanceof Error) {
        // eslint-disable-next-line no-param-reassign
        logObject = { error: logObject };
      } else if (typeof logObject !== 'object' || logObject === null) {
        return;
      }
      const { error } = logObject;
      const message = logObject.message || (error && error.message) || '';
      const stack = logObject.stack || new Error().stack;

      let errorLog;
      if (error) {
        errorLog = {
          error: {
            message: error.message,
            stack: error.stack,
          },
        };
      }
      if (error && error.isAxiosError) {
        const { response, config = {} } = error;
        errorLog = {
          ...errorLog,
          http: {
            type: 'OUTBOUND_API_RESPONSE',
            request: {
              userTime: error.requestUserTime,
              httpMethod: config.method,
              host: (config.baseURL || '')
                .replace(/^https?:\/\//, '')
                .split('/')[0],
              url:
                typeof config.url === 'string'
                  ? `${config.baseURL || ''}${config.url}`
                  : undefined,
              path:
                typeof config.url === 'string'
                  ? config.url.split('?')[0]
                  : undefined,
              queryString:
                typeof config.url === 'string'
                  ? config.url.split('?')[1] || ''
                  : undefined,
              headers: config.headers,
              body: stringify(config.data),
              aborted: error.request?.res?.aborted,
            },
            response: response && {
              userTime: error.responseUserTime,
              message: error.message,
              statusCode: toNumber(response.status),
              // statusText: response.statusText,
              headers: response.headers,
              body: stringify(response.data),
            },
          },
        };
      }

      this.buffer.push(
        Object.assign(logObject || {}, {
          url: window && window.location.href,
          level: type.toUpperCase(),
          message,
          stack,
          unexpectedError,
          ...errorLog,
        }),
      );

      if (unexpectedError) {
        this.flush();
      } else {
        this.delayedFlush();
      }
    }
  } catch (err) {
    // DO NOT log with console.error, to prevent infinite cyclic logging
    // @ts-ignore
    (console._error || console.debug)(err);
  }
};

Logger.prototype.flush = async function flush() {
  if (!this.buffer.length) return;
  /** @type {boolean|Error} */
  let returnVal;
  try {
    const userInfo = {
      os: browser.os,
      browserName: browser.name,
      browserVersion: browser.version,
      timeZone,
      screenResolution,
      // viewport can be resized anytime, so don't hoist it
      viewportDimension: {
        width: document.body?.offsetWidth,
        height: document.body?.offsetHeight,
      },
    };
    returnVal = await this.dispatchPayload(this.url, {
      logs: this.buffer,
      userInfo,
    });
  } catch (err) {
    // DO NOT log with console.error, to prevent infinite cyclic logging
    // @ts-ignore
    (console._error || console.debug)(err);
    returnVal = /** @type {Error} */ (err);
  }
  // if dispatcher specifically returns false, it means that
  // the dispatcher didn't attempt logging. Reattempt next time
  if (returnVal !== false) {
    this.buffer = [];
  }
};

// initialize
if (window) {
  const logger = new Logger();
  // @ts-ignore
  window.$logger = logger;

  const _onerror = window.onerror;
  const pastLogCounter = {};
  // Handle unexpected errors
  window.onerror = function onerror(...args) {
    const [message, source, lineno, colno, error] = args;
    let stack = error && error.stack;
    if (!error && source) {
      const point = [lineno, colno].join(':');
      stack = source + (point && point !== ':' ? ` (${point})` : '');
    }
    const logKey = `${message}~${stack}`;
    const pastLogCount = pastLogCounter[logKey] || 0;
    // log a message-stack combination only upto 3 times
    if (pastLogCount < 3) {
      pastLogCounter[logKey] = pastLogCount + 1;
      logger.addToQ('ERROR', [{ message, stack }], true, false);
    }
    if (_onerror) {
      return _onerror.apply(window, args);
    }
    return false;
  };
}
