import config from '../config';

const DEFAULT_ERROR_MESSAGE = 'Something went wrong! Please try again.';

export default (err) => {
  if (!config.isProd) console.error(err);
  return err.response?.data?.message
    || err?.response?.data?.errors?.[0]
    || (typeof err.response?.data === 'string' ? err.response?.data : undefined)
    || err.message
    || DEFAULT_ERROR_MESSAGE;
};
