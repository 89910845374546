import hashFunc from 'fnv1a';
import {
  set, get, keyBy,
  isEmpty,
} from 'lodash';
import { isLogoutRequested, logout, clearLogin } from '../logout';
import auth0Client from '../auth0client';
import { getStates, resetStore, updateAuth } from '../store';
import { push, replace } from './route';
import * as toastActions from './toast';
import { setCookie } from '../cookie';
import { listen } from '../broadcastChannel';
import * as apiClient from '../api-client';

const promisify = (callbackFunction) => (...args) => new Promise((resolve, reject) => {
  callbackFunction(...args, (err, result) => {
    if (err) {
      reject(err);
      return;
    }
    resolve(result);
  });
});

const auth0ParseHash = promisify(auth0Client.parseHash.bind(auth0Client));
const auth0CheckSession = promisify(auth0Client.checkSession.bind(auth0Client));

const populateAccessByTenantIdList = async (accessByTenantId = {}, hasAnyAccess = false) => {
  const assignedTenants = Object.keys(accessByTenantId);
  const userTenants = keyBy(
    await apiClient.getUserTenants(hasAnyAccess ? undefined : assignedTenants[0]),
    'value',
  );
  const accessibleTenants = hasAnyAccess ? Object.keys(userTenants) : assignedTenants;
  const populatedAccess = accessibleTenants.reduce((acc, tenantId) => ({
    ...acc,
    [tenantId]: {
      name: userTenants[tenantId]?.name,
      // eslint-disable-next-line no-underscore-dangle
      ...(accessByTenantId[tenantId] || accessByTenantId._ANY),
    },
  }), {});
  return populatedAccess;
};


const getTenantInfo = (accessByTenantId = {}, tenantId) => {
  const tenantData = accessByTenantId?.[tenantId] || {};
  if (isEmpty(tenantData)) return {};
  const {
    role, merchants, locations, countries,
  } = tenantData;

  if (merchants) merchants.sort();

  const canAccessAllMerchants = !merchants;

  return {
    // properties for currently logged-in tenant
    tenantId,
    role,
    merchants,
    canAccessAllMerchants,
    locations,
    countries,
  };
};

const saveLastSelectedTenantId = ({
  tenantId,
  connector,
  carriyoUserId,
}) => {
  set(window, `sessionStorage.userPref-${connector}-${hashFunc(carriyoUserId)}-selectedTenantId`, tenantId);
};

const fetchLastSelectedTenantId = ({
  connector,
  carriyoUserId,
  accessByTenantId,
}) => {
  let tenantId = get(window, `sessionStorage.userPref-${connector}-${hashFunc(carriyoUserId)}-selectedTenantId`);
  if (!tenantId || !accessByTenantId[tenantId]) {
    const tenantIds = Object.keys(accessByTenantId || {});
    if (tenantIds.length === 1) {
      // pick first one alphabetically
      // eslint-disable-next-line prefer-destructuring
      tenantId = tenantIds.sort()[0];
      saveLastSelectedTenantId({ tenantId, connector, carriyoUserId });
    }
  }
  return tenantId ? getTenantInfo(accessByTenantId, tenantId) : {};
};

export const authorize = async () => {
  // 1. Check if a logout request was present
  if (isLogoutRequested()) {
    clearLogin();
  }
  // 2. check state
  const state = getStates();
  if (state.auth.accessToken) {
    return;
  }

  // 3. check local storage
  const authMetaFromLocalStorage = JSON.parse(localStorage.getItem('auth'));
  if (
    authMetaFromLocalStorage
    && Date.now() < authMetaFromLocalStorage.expiresAt
  ) {
    const { pathname } = window.location;
    const regex = /\/tenants\/([^\\/]+)(\/|$)/;
    const tenantFromURL = pathname.match(regex)?.[1] || null;
    const {
      accessByTenantId,
      carriyoUserId,
      connector,
    } = authMetaFromLocalStorage;

    if (tenantFromURL) {
      saveLastSelectedTenantId({
        tenantId: tenantFromURL,
        connector,
        carriyoUserId,
      });
    }
    const tenantInfo = fetchLastSelectedTenantId({
      connector,
      carriyoUserId,
      accessByTenantId,
    });
    updateAuth({
      authorizing: false,
      ...authMetaFromLocalStorage,
      ...tenantInfo,
    });
    return;
  }

  updateAuth({ authorizing: true });

  try {
    // 4. check url
    if (
      window.location.hash.includes('access_token=')
      || window.location.hash.includes('error=')
    ) {
      let parsedData;
      try {
        parsedData = (await auth0ParseHash({
          hash: window.location.hash,
        })) || {};
      } catch (err) {
        console.error(err);
        const { errorDescription = '' } = err;
        if (errorDescription.startsWith('User does not exist')) {
          toastActions.error(`Access denied. Your username has not been created with Carriyo yet. 
          Please ask your organization\\'s Carriyo admin 
          to create user first and then try to login again.`, Infinity);
        } else if (errorDescription.includes('`state` does not match.')) {
          // expired/invalid state. ask customer to login again
          auth0Client.authorize({});
          return;
        } else {
          toastActions.error('Unexpected error during login. Please inform carriyo support.', Infinity);
        }
        return;
      }

      const {
        accessToken = '',
        expiresIn = 0,
        idTokenPayload = {},
        idTokenPayload: {
          sub,
          email,
        } = {},
      } = parsedData;

      const {
        connector = 'global',
        userId: carriyoUserId,
        name,
        picture,
        accessByTenantId,
      } = idTokenPayload[`${window.location.origin}/app_metadata`] || {};
      localStorage.setItem('idTokenPayload', JSON.stringify(idTokenPayload));
      let updatedAccessByTenantIds = accessByTenantId;
      const assignedTenantList = Object.keys(accessByTenantId).sort();
      if (!assignedTenantList.length) {
        updateAuth({ authorizing: false });
        return;
      }
      const hasAnyAccess = assignedTenantList.includes('_ANY');

      // save accessToken to auth state here as middleware endpoint using this in authorization
      let authMeta = { accessToken, carriyoUserId };
      updateAuth(authMeta);
      updatedAccessByTenantIds = await populateAccessByTenantIdList(accessByTenantId, hasAnyAccess);
      const tenantInfo = fetchLastSelectedTenantId({
        connector,
        carriyoUserId,
        accessByTenantId: (
          assignedTenantList.length > 1 || hasAnyAccess
        ) ? {} : updatedAccessByTenantIds,
      });
      authMeta = {
        ...authMeta,
        expiresAt: Date.now() + expiresIn * 1000,
        name,
        email,
        picture,
        accessByTenantId: updatedAccessByTenantIds,
        connector,
        auth0UserId: connector === 'global' ? sub : undefined,
      };

      updateAuth({
        authorizing: false,
        ...authMeta,
        ...tenantInfo,
      });
      localStorage.setItem('auth', JSON.stringify(authMeta));
      localStorage.setItem('lastSessionCheck', Date.now());
      setCookie({
        lastLogin: Date.now(),
        lastLogout: undefined,
      });

      replace('/');
      return;
    }

    // 5. redirects customer to auth0 for login
    auth0Client.authorize({});
  } catch (err) {
    // eslint-disable-next-line no-console
    console.error(err);
  }
};

export const setTenantId = (tenantId, avoidRedirection = false) => {
  const {
    auth: {
      authorizing,
      ...authMeta
    } = {},
  } = getStates();

  const newAuthMeta = { ...authMeta, ...getTenantInfo(authMeta?.accessByTenantId, tenantId) };
  resetStore(newAuthMeta);
  saveLastSelectedTenantId({
    tenantId,
    connector: authMeta.connector,
    carriyoUserId: authMeta.carriyoUserId,
  });

  const composedPath = avoidRedirection
  // Avoid redirection where we are redirecting generic routes to tenant based routes
    ? `/tenants/${tenantId}/${window.location.pathname.split('/').slice(3).join('/')}`
  // on tenant switch, redirect from edit/create pages to the listing page using URL structure.
  // also remove URL params as they may not be relevant
    : [...window.location.pathname.split('/').slice(0, 2), tenantId].join('/');
  push(composedPath, false);
};

let checkSessionTimer;
const fifteenMinInMs = 15 * 60 * 1000;
export const pollEnterpriseSSOSessionCheck = () => {
  const {
    auth: {
      authorizing,
      auth0UserId,
    },
    global: {
      tenantSettings: {
        enterpriseSsoEnabled,
      },
    },
  } = getStates();
  if (authorizing || auth0UserId || !enterpriseSsoEnabled) return;

  const checkSession = async () => {
    // check session. If error log out
    try {
      await auth0CheckSession({});
      localStorage.setItem('lastSessionCheck', Date.now());
    } catch (err) {
      logout();
    }
  };

  const resetTimer = () => {
    clearInterval(checkSessionTimer);
    if (document.visibilityState !== 'hidden') {
      // if user logged out of another tab, then log them out now.
      if (!localStorage.getItem('auth')) {
        logout();
        return;
      }
      checkSessionTimer = setInterval(checkSession, fifteenMinInMs);
      const lastCheck = parseInt(localStorage.getItem('lastSessionCheck'), 10) || Date.now();
      if ((Date.now() - lastCheck) > fifteenMinInMs) {
        checkSession();
      }
    }
  };

  // start timer if tab is open
  resetTimer();
  // start timer on tab focus switch
  document.addEventListener('visibilitychange', resetTimer);
};

// --- Events ---

// real-time logout (Chrome)
listen('logoutRequest', () => logout(false));

// delayed logout (Safari)
document.addEventListener('visibilitychange', () => {
  if (document.visibilityState === 'visible' && isLogoutRequested()) {
    authorize();
  }
});
