import camelCaseKeys from 'lib-frontend-shared/src/helpers/camelCaseKeys';

import { updateGlobal } from '../store';

import * as apiClient from '../api-client';
import { reader } from '../helpers/action';

const entity = 'reason codes';

// eslint-disable-next-line import/prefer-default-export
export const getAllReasonCodes = reader(
  entity,
  async ({ bustCache = false } = {}) => {
    const { data = [] } = await apiClient.getAllReasonCodes({ bustCache });
    const reasonCodes = camelCaseKeys(data);
    updateGlobal({ reasonCodes });
    return reasonCodes;
  },
);
