import { castArray, get } from 'lodash';
import { getStates, history } from '../store';

const routeStack = {
  previousRouteStack: [],
  hasPreviousPath: false,
};
const getPreviousPathInfo = (explicitStack = [], onlyFetchData = false) => {
  const {
    route: {
      breadcrumbs,
    } = {},
  } = getStates();
  if (onlyFetchData) {
    return routeStack;
  }
  const currentPath = get(history, 'location.pathname', '');
  let previousRouteStack = castArray(get(history, 'location.state.from', []));
  if (!previousRouteStack.length && explicitStack.length > 1) {
    previousRouteStack = explicitStack;
  } else if (!previousRouteStack.length) {
    previousRouteStack = ['/', ...(breadcrumbs.length > 0 ? breadcrumbs : [])].map((rId) => rId).reverse();
  }
  const hasPreviousPath = previousRouteStack.length > 1
    || (previousRouteStack.length === 1
    && previousRouteStack[0] !== currentPath);
  routeStack.previousRouteStack = previousRouteStack;
  routeStack.hasPreviousPath = hasPreviousPath;
  return {
    previousRouteStack,
    hasPreviousPath,
  };
};
export default getPreviousPathInfo;
