/* eslint-disable no-nested-ternary */
import {
  castArray,
  uniq,
} from 'lodash';
import {
  getStates, updateCarrier,
} from '../store';
import * as apiClient from '../api-client';
import { reader } from '../helpers/action';
import { fetchNonExistingIds, upsertEntity } from './cached-entities';

export const setState = updateCarrier;

const singularEntity = 'carrier';
const pluralEntities = `${singularEntity}s`;

export const getCarrierList = reader(
  pluralEntities,
  async ({
    searchString,
    allFilters,
  } = {}) => {
    const { carriers = [] } = await apiClient.getCarrierList({
      page: 0,
      pageSize: 100,
      searchString,
      status: 'ACTIVE',
      sortBy: 'carrier_account_name',
      sortDirection: 'asc',
      allFilters,
      fields: [],
      excludeClicknshipChildAccounts: false,
    });
    upsertEntity({
      entity: pluralEntities,
      list: carriers,
      identifier: 'carrierId',
    });
    return carriers;
  },
);

export const getCarrierDetails = reader(
  pluralEntities,
  async ({
    carrierIds = [],
    accountNames = [],
    carrierNames = [],
    allMerchants = true,
  } = {}) => {
    const { cachedEntities: { carriers: existingCarriers = [] } = {} } = getStates();
    if (!carrierIds.length && !accountNames.length && !carrierNames.length) {
      return { carriers: existingCarriers };
    }
    const castedCarrierIds = uniq(castArray(carrierIds).filter(Boolean));
    const castedCarrierNames = uniq(castArray(carrierNames).filter(Boolean));
    const castedAccountNames = uniq(castArray(accountNames).filter(Boolean));

    if (castedCarrierIds.length < 1
        && castedAccountNames.length < 1
        && castedCarrierNames.length < 1) {
      return { carriers: existingCarriers };
    }
    const identifier = castedCarrierIds.length ? 'carrierId' : (castedCarrierNames.length ? 'name' : 'accountName');
    const nonExistingIdentifiers = fetchNonExistingIds({
      identifiers: castedCarrierIds.length
        ? castedCarrierIds
        : (castedCarrierNames.length ? castedCarrierNames : castedAccountNames),
      entity: pluralEntities,
      identifier,
    });
    if (nonExistingIdentifiers.length > 0) {
      const { global: { selectedMerchantIds, merchantIds } } = getStates();
      // eslint-disable-next-line no-param-reassign
      allMerchants = allMerchants || merchantIds.length === selectedMerchantIds.length;
      const queryIdentifier = castedCarrierIds.length ? 'carrierIds' : (castedCarrierNames.length ? 'carrierNames' : 'accountNames');
      const { carriers = [] } = await apiClient.getCarrierDetails({
        [queryIdentifier]: nonExistingIdentifiers,
        allMerchants,
      });
      const { carriers: updatedCarriers } = upsertEntity({
        entity: pluralEntities,
        list: carriers.filter(({ carrierId }) => Boolean(carrierId)),
        identifier: 'carrierId',
      });
      return { carriers: updatedCarriers };
    }
    const { cachedEntities: { carriers = [] } = {} } = getStates();
    return { carriers };
  },
);
