import React from 'react';
import { Tooltip as MuiTooltip } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';

const TooltipRenderer = withStyles(() => ({
  tooltip: {
    backgroundColor: 'rgba(0, 0, 0, 0.75)',
  },
}))(MuiTooltip);

const Tooltip = ({ children, title, ...rest }) => (title ? <TooltipRenderer title={title} {...rest}>{children}</TooltipRenderer> : children);

export default Tooltip;
