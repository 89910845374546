import React from 'react';

import cls from '../helpers/cls';

import './CircularProgress.css';

const CircularProgress = ({
  color = 'secondary',
  className,
  size = 40,
  style,
  value,
}) => {
  const indeterminate = typeof value !== 'number';
  const radius = size / 2;
  const thickness = size / 12;
  const meanRadius = radius - (thickness / 2);
  const circumference = 2 * Math.PI * meanRadius;
  const pathProps = {
    cx: radius,
    cy: radius,
    fill: 'none',
    r: meanRadius,
    strokeWidth: thickness,
  };
  return (
    <div
      className={cls('CircularProgress', { color, indeterminate }, className)}
      style={{
        ...Object.fromEntries(
          ['minHeight', 'maxHeight', 'height', 'minWidth', 'maxWidth', 'width'].map(
            (prop) => [prop, size],
          ),
        ),
        ...style,
      }}
    >
      <svg className="CircularProgress-circle" style={{ width: size, height: size }}>
        <circle
          {...pathProps}
          className="CircularProgress-forepath"
          strokeDasharray={circumference / (indeterminate ? 2 : 1)}
          strokeDashoffset={indeterminate ? 0 : circumference * (1 - value / 100)}
        />
        <circle {...pathProps} className="CircularProgress-backpath" />
      </svg>
    </div>
  );
};

export default CircularProgress;
