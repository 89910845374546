import { carriyoClient, getCacheBustHeaders } from './base';

// eslint-disable-next-line import/prefer-default-export
export const getAllReasonCodes = async ({ bustCache = false }) => carriyoClient.get(
  '/reason-codes',
  {
    headers: { ...(bustCache ? getCacheBustHeaders() : {}) },
    params: { cache: 'true' },
  },
);
