import camelCaseKeys from 'lib-frontend-shared/src/helpers/camelCaseKeys';

import { carriyoClient } from './base';
import { getStates } from '../store';

// eslint-disable-next-line import/prefer-default-export
export const getSubscription = async () => {
  const { auth: { tenantId } } = getStates();
  try {
    const { data } = await carriyoClient.get(`/subscription/${tenantId}`);
    return camelCaseKeys(data);
  } catch (err) {
    // 404 needs to be handled as empty response
    if (err.response?.status === 404) {
      return null;
    }
    throw err;
  }
};
