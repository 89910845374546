import useAsyncEffect from 'lib-frontend-shared/src/helpers/useAsyncEffect';
import { getUserDefinedEnums } from '../../actions/global';
import { authorize, pollEnterpriseSSOSessionCheck } from '../../actions/auth';
import useGlobalStates from '../../helpers/useGlobalStates';

function GlobalInitializer() {
  const { auth: { tenantId } } = useGlobalStates(['auth']);
  useAsyncEffect(async () => {
    await authorize();
    // preload this data
    if (!tenantId) return undefined;
    // getUserDefinedEnums() implicitly depends on tenantId
    await getUserDefinedEnums();
    return pollEnterpriseSSOSessionCheck();
  }, [tenantId]);

  return null;
}

export default GlobalInitializer;
