/* eslint-disable no-nested-ternary */
import { chunk, get } from 'lodash';
import camelCaseKeys from 'lib-frontend-shared/src/helpers/camelCaseKeys';
import snakeCaseKeys from 'lib-frontend-shared/src/helpers/snakeCaseKeys';
import {
  carriyoClient,

  // utils
  timeZoneOffset,
  toMerchantsParam,
  nodeMiddlewareClient,
} from './base';
import { getStates } from '../store';
import {
  fromCarrier,
  toCarrier,
  fromCarrierStats,
  fromNetwork,
  toNetwork,
  fromCapacityRule,
  toCapacityRule,
  fromCapacityProfile,
  toCapacityProfile,
} from '../api-transforms';

export const getCarrierList = async ({
  page = 0,
  pageSize = 10,
  searchString,
  status,
  allFilters = {},
  sortBy,
  sortDirection,
  allMerchants = false,
  statistics = false,
  excludeCarrier,
  excludeClicknshipChildAccounts = true,
}, signal) => {
  const {
    global: { selectedMerchantIds },
  } = getStates();

  const {
    data: {
      pagination: carrierPagination = {},
      items = [],
    },
  } = await nodeMiddlewareClient.get('/carrier-accounts', {
    signal,
    params: snakeCaseKeys({
      ...(page ? { page } : {}),
      ...(pageSize ? { pageSize } : {}),
      ...toMerchantsParam(allMerchants ? undefined : selectedMerchantIds),
      ...(status ? { status } : {}),
      ...(searchString ? { searchString } : {}),
      ...(sortBy ? { sortBy } : {}),
      ...((sortBy && sortDirection) ? { sortDirection: sortDirection.toUpperCase() } : {}),
      ...allFilters,
      excludeClicknshipChildAccounts,
      ...(excludeCarrier ? { excludeCarrier } : {}),
      statistics,
      tzoffset: timeZoneOffset,
    }),
  });
  return {
    totalCarriers: get(carrierPagination, 'total'),
    carriers: items.map(fromCarrierStats),
  };
};

export const getCarrierDetails = async ({
  carrierIds = [],
  accountNames = [],
  carrierNames = [],
}) => {
  const carriersToFetch = carrierIds.length
    ? carrierIds : (accountNames.length ? accountNames : carrierNames);
  const fieldProps = carrierIds.length ? 'carrier_id' : (accountNames.length ? 'account_name' : 'carrier');

  const allRequestedCarriers = await Promise.all(chunk(carriersToFetch, 20).map((identifiers) => nodeMiddlewareClient.get('/frontend/carrier-accounts', {
    params: {
      [fieldProps]: identifiers,
      tzoffset: timeZoneOffset,
    },
  })));
  return {
    carriers: allRequestedCarriers.flatMap(({ data }) => get(data, 'items', [])).map(fromCarrier),
  };
};

export const upsertCarrierSetting = async (body) => {
  const { carrierId } = body;
  const carrier = toCarrier(body);
  if (carrierId) {
    await carriyoClient.put(`/carrier-accounts/${carrierId}`, null, { data: carrier });
    return { carrierId };
  }
  const { data } = await carriyoClient.post('/carrier-accounts', null, { data: carrier });
  return { carrierId: data.carrier_account_id };
};

export const deleteCarrier = ({ carrierId }) => carriyoClient
  .delete(`/carrier-accounts/${carrierId}`, { data: {} });

// CARRRIER NETWORKS

export const getAllNetworks = async () => {
  const { data } = await carriyoClient.get('/networks');
  return (data || []).map(fromNetwork);
};

export const updateNetwork = async (payload) => {
  const { networkId } = payload;
  const body = toNetwork(payload);
  const { data } = await carriyoClient.put(`/networks/${networkId}`, body);
  return fromNetwork(data);
};

export const addNetwork = async (payload) => {
  const body = toNetwork(payload);
  const { data } = await carriyoClient.post('/networks', body);
  return fromNetwork(data);
};

export const assignNetworkToCarriers = async (networkId, carrierList = []) => {
  const { data } = await carriyoClient.post(`/networks/${networkId}/assign`, carrierList);
  return data;
};

export const deleteNetwork = async (networkId) => {
  const { data } = await carriyoClient.delete(`/networks/${networkId}`);
  return data;
};

// CARRIER CAPACITY

export const getCapacityProfiles = async () => {
  const { data } = await carriyoClient.get('/capacities');
  return data.map(fromCapacityProfile);
};

export const createCapacityProfile = async (capacityProfile) => {
  const { data } = await carriyoClient.post('/capacities', toCapacityProfile(capacityProfile));
  return fromCapacityProfile(data);
};

export const assignCarrierAccountsToProfile = async (profileId, carrierIds = []) => {
  const { data } = await carriyoClient.post(`/capacities/${profileId}/assign`, carrierIds);
  return fromCapacityProfile(data);
};

export const deleteCapacityProfile = async (profileId) => {
  const { data } = await carriyoClient.delete(`/capacities/${profileId}`);
  return data;
};

export const updateCapacityProfile = async (capacityProfile) => {
  const { profileId } = capacityProfile;
  const { data } = await carriyoClient.patch(`/capacities/${profileId}`, toCapacityProfile(capacityProfile));
  return fromCapacityProfile(data);
};

export const getCapacityProfile = async (profileId) => {
  const { data } = await carriyoClient.get(`/capacities/${profileId}`);
  return fromCapacityProfile(data);
};

export const getCapacityProfileRules = async (profileId) => {
  const states = getStates();
  const {
    global: {
      locations,
    },
  } = states;
  const { data } = await carriyoClient.get(`/capacities/${profileId}/rules`);
  const alteredData = data.map((selectedData) => fromCapacityRule(selectedData, { locations }));
  return alteredData;
};

export const createCapacityProfileRules = async (profileId, ruleConfig) => {
  const { data } = await carriyoClient.post(`/capacities/${profileId}/rules`, toCapacityRule(ruleConfig));
  return camelCaseKeys(data);
};
export const updateCapacityProfileRules = async (profileId, capacityId, ruleConfig) => {
  const { data } = await carriyoClient.put(`/capacities/${profileId}/rules/${capacityId}`, toCapacityRule(ruleConfig));
  return camelCaseKeys(data);
};

export const deleteCapacityProfileRules = async (profileId, capacityId) => {
  const { data } = await carriyoClient.delete(`/capacities/${profileId}/rules/${capacityId}`);
  return camelCaseKeys(data);
};

export const updateCarrierCapacityRuleSequences = async (profileId, rules) => {
  const body = rules.map(({ capacityId, sequence }) => ({
    id: capacityId,
    sequence,
  }));
  const { data } = await carriyoClient.patch(
    `/capacities/${profileId}/rules/sequences`,
    JSON.stringify(snakeCaseKeys(body)),
    {
      headers: { 'content-type': 'application/json' },
    },
  );
  return data;
};
