import React from 'react';
import {
  TextField as MuiTextField,
} from '@material-ui/core';

import { withStyles } from '@material-ui/core/styles';
import cls from 'lib-frontend-shared/src/helpers/cls';
import './TextField.scss';
import InputLabel from './InputLabel';

const activeBorderStyle = {
  borderColor: '#00063b',
  borderWidth: '1px',
};

const commonOutlinedProps = {
  background: 'transparent',
  borderRadius: '10px',
  '& fieldset': {
    borderColor: '#777777',
    borderWidth: '1px',
    transition: 'border-color 0.125s linear',
  },
  '&:hover fieldset': activeBorderStyle,
  '&.Mui-focused fieldset': activeBorderStyle,
  '&.Mui-disabled:hover fieldset': {
    borderColor: 'rgba(0, 0, 0, 0.26)',
    borderWidth: '1px',
  },
  '&.MuiOutlinedInput-multiline': { padding: 0 },
};

const commonTextProps = {
  color: '#202020',
  '&:hover': { color: '#00063b' },
  '&:focus': { color: '#00063b' },
  '&:disabled': {
    color: '#808080',
    '&::placeholder': {
      color: '#AAAAAA',
    },
  },
};

export const InputField = withStyles(() => ({
  root: {
    '& .MuiInput-input': {
      ...commonTextProps,
      '&:out-of-range': {
        'border-bottom': 'none',
      },
    },
    '& .MuiOutlinedInput-root': commonOutlinedProps,
    '& .MuiOutlinedInput-input': { padding: '10px 15px' },
  },
}))(MuiTextField);

const CustomTextField = React.forwardRef(({
  className = '',
  inputProps = {},
  InputProps = {},
  ...props
}, ref) => (
  <InputField
    {...props}
    className={cls('TextField-inputField ', { }, className)}
    inputProps={{
      ...inputProps,
      className: `TextField-input ${inputProps.className || ''}`,
    }}
    // eslint-disable-next-line react/jsx-no-duplicate-props
    InputProps={{ ...InputProps, disableUnderline: true }}
    ref={ref}
  />
));

// eslint-disable-next-line func-names
export default function (props) {
  return <InputLabel {...props} Input={CustomTextField} />;
}
