import React, { useEffect, useState } from 'react';

import naturalSort from 'lib-frontend-shared/src/helpers/naturalSort';
import FormButton from 'lib-frontend-shared/src/components/FormButton';
import Linear from 'lib-frontend-shared/src/components/Linear';
import Spacer from 'lib-frontend-shared/src/components/Spacer';
import Sticker from 'lib-frontend-shared/src/components/Sticker';
import Typography from 'lib-frontend-shared/src/components/Typography';

import atomicCube from 'lib-frontend-shared/src/assets/images/branding/atomic-cube.png';
import decoratedBird from 'lib-frontend-shared/src/assets/images/branding/bird-decorated.png';
import largeDecoratedBird from 'lib-frontend-shared/src/assets/images/branding/bird-decorated-large.png';
import logo from '../../images/carriyo-logo-dark.svg';

import config from '../../config';

import { useGlobalStates } from '../../store';
import * as authActions from '../../actions/auth';
import * as dialogActions from '../../actions/dialog';

import Dialog from '../Dialog';
import InputLabel from '../InputLabel';
import MaterialAutocomplete from '../MaterialAutocomplete';
import { InputField as TextField } from '../TextField';

import './AccountSwitchDialog.scss';
import MobileSelect from '../MobileSelect';

const standaloneStickers = [{
  key: 'bird-right',
  bottom: '-66px',
  right: '-66px',
  src: largeDecoratedBird,
  transform: 'scale(0.75)',
}, {
  key: 'logo',
  src: logo,
  top: '-250px',
  transform: 'translateX(-46%) scale(0.05)',
}];

const standardStickers = [{
  key: 'atomic-cube-top',
  left: '-40%',
  src: atomicCube,
  top: '-45%',
}, {
  key: 'bird-right',
  left: '72%',
  src: decoratedBird,
  top: '75%',
  transform: 'scaleX(-1)',
}];

const RenderTenantOption = ({ label, value }) => (
  <div>
    {`${label}${value && value === label ? '' : ` (${value})`}`}
  </div>
);

function AccountSwitchDialog({
  standalone = false, name, view = 'desktop',
}) {
  const {
    auth: {
      tenantId: activeTenantId,
      accessByTenantId,
    },
    dialogToDisplay,
  } = useGlobalStates(['auth', 'dialogToDisplay']);

  const tenantOptions = Object.entries(accessByTenantId).map(([key, { name: tenantName }]) => ({
    label: tenantName || key,
    value: key,
  })).sort(naturalSort.by('label'));

  useEffect(() => {
    if (
      activeTenantId
      && accessByTenantId[activeTenantId].role === 'owner'
      && !accessByTenantId[activeTenantId].apiKey
    ) {
      if (tenantOptions.length > 1) authActions.setTenantId(undefined);
      window.location = `${config.accountAppBaseUrl}/subscription`;
    }
  }, [activeTenantId]);

  const open = dialogToDisplay === name;

  const [selectedTenantId, setTenantId] = useState();
  const [state, setState] = useState('ready');

  useEffect(() => {
    if (open) {
      setState('ready');
      setTenantId(activeTenantId);
    }
  }, [open]);

  useEffect(() => {
    if (activeTenantId !== selectedTenantId) setTenantId(activeTenantId);
  }, [activeTenantId]);

  const onClose = () => dialogActions.showDialog('');

  const onSave = () => {
    setState('waiting');
    if (activeTenantId !== selectedTenantId) {
      authActions.setTenantId(selectedTenantId);
    }
    onClose();
  };

  return view === 'mobile' ? (
    <MobileSelect
      legend="Select Account"
      name="tenant"
      options={tenantOptions}
      open={open}
      value={selectedTenantId}
      onChange={({ target: { value } }) => {
        authActions.setTenantId(value);
        onClose();
      }}
    />
  ) : (
    <Dialog
      closeOnBlur={!standalone}
      onClose={onClose}
      open={open}
      showClose={!standalone}
      standalone
      height={standalone ? 'xx' : 'sm'}
      width={standalone ? 'xx' : 'md'}
    >
      <Linear
        align="center"
        gap="md"
        justify="center"
        orientation="vertical"
        height="100pr"
        style={{ zIndex: 1 }}
      >
        <Spacer flex y="3xl" />
        <Typography variant="h2">
          {`${standalone ? 'Select' : 'Switch'} Account`}
        </Typography>
        <Spacer y="sm" />
        {!standalone && (
          <Typography italic variant="para.sm">
            Select the account you want to switch to from the list below.
          </Typography>
        )}
        <InputLabel
          clearable={false}
          extraFilteringEnums={['value']}
          freeSolo={false}
          Input={MaterialAutocomplete}
          inputProps={{ InputComponent: TextField }}
          onChange={setTenantId}
          options={tenantOptions}
          RenderComponent={RenderTenantOption}
          textFieldProps={{
            inputProps: {
              style: { width: '360px' },
            },
          }}
          value={selectedTenantId}
        />
        <Spacer y="md" flex={!standalone} />
        <FormButton
          onClick={onSave}
          state={!selectedTenantId ? 'disabled' : state}
          width={standalone ? 'full' : undefined}
        >
          {standalone ? 'Sign In' : 'Switch'}
        </FormButton>
        <Spacer y="2xl" />
        <div className="AccountSwitchDialog-stickerContainer">
          {(standalone ? standaloneStickers : standardStickers).map(
            (sticker) => <Sticker {...sticker} />,
          )}
        </div>
        {standalone && <Spacer flex />}
      </Linear>
    </Dialog>
  );
}

export default AccountSwitchDialog;
