import {
  nodeMiddlewareClient,
} from './base';

// eslint-disable-next-line import/prefer-default-export
export const getUserTenants = async (tenantId) => {
  const { data } = await nodeMiddlewareClient.get(
    '/tenants/user-tenants',
    tenantId ? { headers: { 'tenant-id': tenantId } } : {},
  );
  return data;
};
