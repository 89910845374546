import { useState } from 'react';
import { isEqual } from 'lodash';

// return same state if state hasn't changed.
// sometime we need to be efficient in change detection so as to reduce UI re-renders
function useDeepMemo(newState) {
  const [state, setState] = useState(newState);
  if (!isEqual(newState, state)) {
    setState(newState);
    return newState;
  }
  return state;
}

export default useDeepMemo;
