import { lazy } from 'react';
import {
  groupBy,
  map,
  pickBy,
  uniq,
} from 'lodash';

import chain from 'lib-frontend-shared/src/helpers/chain';

import { NoPageComponent } from './shared';
import { permissionGroups } from '../../permissions';

const CreateOrder = lazy(() => import(
  /* webpackChunkName: "[CreateOrder]" */
  '../create-order/CreateOrder'
));

const routes = [
  {
    routeId: 'orders',
    permissionGroup: permissionGroups.SHIPMENT,
    title: 'Orders',
    path: '/',
    navigationLinkId: 'orders',
    Component: lazy(() => import(
      /* webpackChunkName: "[ShipmentList]" */
      '../shipment-list/ShipmentList'
    )),
    MobileComponent: lazy(() => import(
      /* webpackChunkName: "[ShipmentListMobile]" */
      '../shipment-list/mobile-view/ShipmentListMobile'
    )),
  },
  {
    routeId: 'create-order',
    permissionGroup: permissionGroups.SHIPMENT,
    navigationLinkId: 'orders',
    title: 'Create Order',
    path: '/orders/create',
    breadcrumbs: ['orders'],
    gradientBackground: true,
    Component: CreateOrder,
  },
  {
    routeId: 'edit-order',
    permissionGroup: permissionGroups.SHIPMENT,
    navigationLinkId: 'orders',
    title: 'Edit Order',
    breadcrumbs: ['orders', 'order-detail'],
    gradientBackground: true,
    path: '/orders/edit/:shipmentId',
    Component: CreateOrder,
  },
  {
    routeId: 'confirm-order',
    permissionGroup: permissionGroups.SHIPMENT,
    navigationLinkId: 'orders',
    title: 'Confirm Order',
    gradientBackground: true,
    breadcrumbs: ['orders', 'order-detail'],
    path: '/orders/confirm/:shipmentId',
    Component: CreateOrder,
  },
  {
    routeId: 'clone-order',
    permissionGroup: permissionGroups.SHIPMENT,
    navigationLinkId: 'orders',
    title: 'Clone Order',
    gradientBackground: true,
    breadcrumbs: ['orders', 'order-detail'],
    path: '/orders/clone/:shipmentId',
    Component: CreateOrder,
  },
  {
    routeId: 'order-detail',
    permissionGroup: permissionGroups.SHIPMENT,
    navigationLinkId: 'orders',
    path: '/orders/:shipmentId',
    title: 'Order Detail',
    breadcrumbs: ['orders'],
    gradientBackground: true,
    Component: lazy(() => import(
      /* webpackChunkName: "[Shipment]" */
      '../shipment/Shipment'
    )),
  },
  {
    routeId: 'customers',
    permissionGroup: permissionGroups.CUSTOMER,
    title: 'Customers',
    path: '/customers',
    breadcrumbs: ['orders'],
    navigationLinkId: 'customers',
    Component: lazy(() => import(
      /* webpackChunkName: "[CustomerList]" */
      '../customer-list/CustomerList'
    )),
  },
  {
    routeId: 'customer-detail',
    permissionGroup: permissionGroups.CUSTOMER,
    title: 'Contact Info',
    path: '/customers/:customerId',
    navigationLinkId: 'customers',
    gradientBackground: true,
    breadcrumbs: ['customers'],
    Component: lazy(() => import(
      /* webpackChunkName: "[CustomerDetail]" */
      '../customer-detail/CustomerDetail'
    )),
  },
];
const noPageRoute = {
  routeId: 'page-not-found',
  permissionGroup: permissionGroups.NONE,
  title: 'Page Not Found',
  path: '*',
  Component: NoPageComponent,
};

const nonTenantRoutes = [...routes, noPageRoute];

const tenantRoutes = nonTenantRoutes.map((route) => ({
  ...route,
  routeId: `tenant-${route.routeId}`,
  path: `/tenants/:tenantId${route.path !== '/' ? route.path : ''}`,
}));

const allRoutes = [
  ...routes,
  ...tenantRoutes,
  noPageRoute,
];

if (import.meta.env.DEV) {
  const allRouteIds = map(allRoutes, 'routeId');
  if (uniq(allRouteIds).length !== allRouteIds.length) {
    const diff = chain(allRouteIds)
      .fn(groupBy)
      .fn(pickBy, (group) => group.length > 1)
      .fn(Object.keys)
      .value;
    throw new Error(`You have created duplicate routeId in routes.jsx: ${diff.join(',')}`);
  }

  const routeWithoutPermissionGroup = allRoutes.find(({ permissionGroup }) => !permissionGroup);
  if (routeWithoutPermissionGroup) {
    throw new Error(`route id '${routeWithoutPermissionGroup.routeId}' does not have a permission group`);
  }
}

export default allRoutes;
