/* eslint-disable camelcase */
import {
  camelCase,
  find,
  get as lodashGet,
  keyBy,
  map,
  mapValues,
} from 'lodash';

import camelCaseKeys from 'lib-frontend-shared/src/helpers/camelCaseKeys';
import snakeCaseKeys from 'lib-frontend-shared/src/helpers/snakeCaseKeys';
import toNumber from 'lib-frontend-shared/src/helpers/toNumber';
import * as enums from './enums';
import moment from './helpers/moment';
// FIXME: there should be no date formatting in this file
import formatDate from './helpers/formatDate';

// types
import './types/PartnerLocation';
import './types/CountryStateAndCityTree';

const DATE_FORMAT_MONTH_AND_TIME = 'D MMM hh:mm a';

const getLocalMoment = (dateTimeInGMT) => (
  dateTimeInGMT
    ? moment(dateTimeInGMT).local()
    : undefined
);

const isoTimestamp = (timestamp) => {
  if (!timestamp) return null;
  return moment(timestamp).toISOString();
};

const get = (obj, path, defaultVal) => lodashGet(obj, path) ?? defaultVal;

const getSteps = (shipment) => {
  const status = get(shipment, 'post_shipping_info.status', '');
  const statusLowerCased = status.toLowerCase().trim();
  const stepStatusMapping = [{
    label: () => 'Order Placed',
    // completed appears with a tick mark in UI
    completed: [
      'draft',
      'pending',
      'error',
      'booked',
      'ready_to_ship',
      'shipped',
      'in_transit',
      'out_for_delivery',
      'awaiting_customer_collection',
      'delivered',
      'delivery_confirmed',
      'failed_collection_attempt',
      'failed_delivery_attempt',
      'ready_for_return',
      'return_in_transit',
      'returned',
      'return_confirmed',
      'missing',
      'delayed',
      'cancelled',
      'suspended',
    ],
    // "active" means "some processing is going on". It's highlighted in UI
    active: [],
    error: [],
    // first date/time found in this list will be shown
    dateProp: ['order_date'],
  }, {
    label: () => 'Processed',
    completed: [
      'pending',
      'error',
      'booked',
      'ready_to_ship',
      'shipped',
      'in_transit',
      'out_for_delivery',
      'awaiting_customer_collection',
      'delivered',
      'delivery_confirmed',
      'failed_collection_attempt',
      'failed_delivery_attempt',
      'ready_for_return',
      'return_in_transit',
      'returned',
      'return_confirmed',
      'missing',
      'delayed',
      'cancelled',
      'suspended',
    ],
    active: [],
    error: [],
    dateProp: ['confirmation_date'],
    estimatedDateProp: 'estimated_process_date',
    completedDateProp: ['confirmation_date'],
  }, {
    label: () => 'Collected',
    completed: [
      'shipped',
      'in_transit',
      'out_for_delivery',
      'awaiting_customer_collection',
      'delivered',
      'delivery_confirmed',
      'failed_delivery_attempt',
      'ready_for_return',
      'return_in_transit',
      'returned',
      'return_confirmed',
      'missing',
      'delayed',
      'cancelled',
      'suspended',
    ],
    active: [],
    error: [
      'error',
      'failed_collection_attempt',
    ],
    dateProp: [
      'post_shipping_info.key_milestones.failed_collection_attempt',
      'post_shipping_info.key_milestones.shipped',
    ],
    estimatedDateProp: 'post_shipping_info.estimated_ship_date',
    completedDateProp: ['post_shipping_info.key_milestones.shipped'],
  }, {
    label: (state) => {
      if (['ready_for_return', 'return_in_transit', 'returned', 'return_confirmed'].includes(statusLowerCased)) {
        return 'Returned';
      }
      if (state === 'error') {
        return 'Failed Delivery';
      }
      return 'Delivered';
    },
    completed: [
      'delivered',
      'delivery_confirmed',
    ],
    active: [],
    error: [
      'missing',
      'delayed',
      'cancelled',
      'suspended',
      'failed_delivery_attempt',
      'ready_for_return',
      'return_in_transit',
      'returned',
      'return_confirmed',
    ],
    dateProp: [
      'post_shipping_info.key_milestones.returned',
      'post_shipping_info.key_milestones.return_in_transit',
      'post_shipping_info.key_milestones.ready_for_return',
      'post_shipping_info.key_milestones.delivered',
      'post_shipping_info.key_milestones.failed_delivery_attempt',
    ],
    estimatedDateProp: 'post_shipping_info.estimated_delivery_date',
    completedDateProp: [
      'post_shipping_info.key_milestones.delivered',
      'post_shipping_info.key_milestones.ready_for_return',
      'post_shipping_info.key_milestones.return_in_transit',
      'post_shipping_info.key_milestones.returned',
    ],
  }];
  const steps = stepStatusMapping
    .filter(({
      showIf,
      orderType = get(shipment, 'delivery.delivery_type', ''),
    }) => !showIf || showIf({ orderType }))
    .map(({
      label: labelFunction,
      completed,
      active,
      error = [],
      dateProp,
      estimatedDateProp,
      completedDateProp,
    }) => {
      const states = {
        completed: completed.includes(statusLowerCased),
        active: active.includes(statusLowerCased),
        error: error.includes(statusLowerCased),
      };
      const state = Object.keys(states).filter((key) => states[key])[0] || 'inactive';
      const date = dateProp
        .map((prop) => get(shipment, prop))
        .find(Boolean);
      const estimatedDate = get(shipment, estimatedDateProp);
      const completedDate = (completedDateProp || [])
        .map((prop) => get(shipment, prop))
        .find(Boolean);
      const pastEstimate = ( // true or falsy
        (estimatedDate && moment(estimatedDate).isBefore(completedDate || Date.now()))
      );

      return {
        label: labelFunction(state),
        ...states,
        date: formatDate(date, DATE_FORMAT_MONTH_AND_TIME),
        estimatedDate: formatDate(estimatedDate, DATE_FORMAT_MONTH_AND_TIME),
        completedAndMetEstimate: ( // tri-state - true, false or undefined
          (estimatedDate
          && completedDate && !pastEstimate)
        ),
        outstandingAndPastEstimate: ( // true or falsy
          (!state.completed && pastEstimate)
        ),
      };
    });

  return steps;
};

const getReturnRequestSteps = (returnRequest) => {
  const status = get(returnRequest, 'status', '').toLowerCase().trim();
  const stepStatusMapping = [{
    label: 'Created',
    // completed appears with a tick mark in UI
    completed: [
      'pending',
      'approved',
      'rejected',
      'completed',
      'cancelled',
    ],
    completedDateProp: ['creation_date'],
    // "active" means "some processing is going on". It's highlighted in UI
    active: [],
    error: ['cancelled'],
    errorDateProp: ['cancellation_date'],
    // first date/time found in this list will be shown
  }, {
    label: 'Approved',
    completed: [
      'approved',
      'completed',
    ],
    completedDateProp: ['approval_date'],
    active: [],
    activeDateProp: [],
    error: ['rejected'],
    errorDateProp: ['rejection_date'],
  }, {
    label: 'Completed',
    completed: ['completed'],
    completedDateProp: ['completion_date'],
    active: [],
    error: [],
  }];
  const steps = stepStatusMapping
    .filter(({ showIf }) => !showIf || showIf())
    .map(({
      label,
      completed,
      completedDateProp,
      active,
      activeDateProp,
      error = [],
      errorDateProp,
    }) => {
      const states = {
        completed: completed.includes(status),
        active: active.includes(status),
        error: error.includes(status),
      };
      const findDate = (props = []) => formatDate(
        props.map((prop) => get(returnRequest, prop)).find(Boolean),
        DATE_FORMAT_MONTH_AND_TIME,
      );
      const activeDate = findDate(activeDateProp);
      const errorDate = findDate(errorDateProp);
      const completedDate = findDate((completedDateProp || []));

      return {
        label,
        ...states,
        date: completedDate || activeDate || errorDate,
      };
    });

  return steps;
};

// formatAddress is not perfect as city and state is not converted from code
// to name if available in global store. (and if not available then fetch /cities for country)
const formatAddress = (addressObject) => {
  // eslint-disable-next-line no-param-reassign
  addressObject = addressObject ?? {};
  const props = {
    area: addressObject.area,
    state: addressObject.state,
    city: addressObject.city,
    country: enums.getShortNameForCountry(addressObject.country),
  };

  return ['area', 'state', 'city', 'country']
    .map((field) => props[field])
    .filter(Boolean)
    .join(', ');
};

export const fromShipment = (shipment = {}) => {
  const status = get(shipment, 'post_shipping_info.status', '');

  const concatFields = ({
    namespace,
    fields,
    delimiter = ', ',
  }) => fields
    .map((field) => get(shipment, [...namespace, field], ''))
    .filter(Boolean)
    .join(delimiter);

  const paymentAmount = get(shipment, 'payment.pending_amount');
  const paymentCurrency = get(shipment, 'payment.currency');
  const documents = get(shipment, 'post_shipping_info.documents', []);
  const commercialInvoice = (
    find(documents, { type: 'commercial_invoice', source: 'carrier' })?.url
    || get(shipment, 'post_shipping_info.commercial_invoice', '')
  );

  return {
    tenant: get(shipment, 'tenant'),
    steps: getSteps(shipment),
    source: {
      sourceType: get(shipment, 'source.source_type'),
    },
    schedulePickupFrom: get(shipment, 'collection.scheduled_from', null),
    schedulePickupTo: get(shipment, 'collection.scheduled_to', null),
    scheduleDropoffFrom: get(shipment, 'delivery.scheduled_from', null),
    scheduleDropoffTo: get(shipment, 'delivery.scheduled_to', null),
    orderRef: get(shipment, 'references.partner_order_reference', ''),
    shipmentRef: get(shipment, 'references.partner_shipment_reference', ''),
    alternateRef: get(shipment, 'references.alternate_reference', ''),
    shipmentId: get(shipment, 'shipment_id', ''),
    manifestId: get(shipment, 'manifest_id', ''),
    merchant: get(shipment, 'merchant', ''),
    entityType: get(shipment, 'entity_type', 'FORWARD'),
    createdAt: formatDate(get(shipment, 'creation_date', '')),
    updatedAt: formatDate(get(shipment, 'update_date', '')),
    orderDate: formatDate(get(shipment, 'order_date', '')),
    confirmationDate: formatDate(get(shipment, 'confirmation_date', '')),
    status,
    lastStatusRefreshed: isoTimestamp(
      get(shipment, 'post_shipping_info.last_status_refresh_date'),
    ),
    customerName: get(shipment, 'dropoff.contact_name', ''),
    orderValue: concatFields({
      namespace: ['payment'],
      fields: ['total_amount', 'currency'],
      delimiter: ' ',
    }),
    pendingAmount: typeof paymentAmount === 'number'
      ? [paymentAmount, paymentCurrency].filter((v) => v !== undefined).join(' ')
      : undefined,
    deliveryType: get(shipment, 'delivery.delivery_type', ''),
    scheduledDateTo: get(shipment, 'delivery.scheduled_to', ''),
    scheduleTimeSlotId: get(shipment, 'delivery.scheduled_time_slot_id', ''),
    paymentType: enums.getLabelFromValue({
      enumName: 'payment_mode',
      value: get(shipment, 'payment.payment_mode', ''),
    }),
    language: get(shipment, 'language'),

    carrierName: get(shipment, 'carrier_account.carrier', ''),
    carrierAccountName: get(shipment, 'carrier_account.carrier_account_name'),
    carrierId: get(shipment, 'carrier_account.carrier_id', ''),
    carrierStatus: get(shipment, 'post_shipping_info.carrier_status', ''),
    carrierStatusDescription: get(shipment, 'post_shipping_info.carrier_status_description', ''),
    carrierTrackingNumber: get(shipment, 'post_shipping_info.tracking_no', ''),
    documentURLs: {
      default: get(shipment, 'post_shipping_info.default_label_url', ''),
      carrierPDF: get(shipment, 'post_shipping_info.carrier_pdf_label_url', ''),
      carrierZPL: get(shipment, 'post_shipping_info.carrier_zpl_label_url', ''),
      carriyoPDF: get(shipment, 'post_shipping_info.carriyo_pdf_label_url', ''),
      carriyoZPL: get(shipment, 'post_shipping_info.carriyo_zpl_label_url', ''),
      ...(commercialInvoice ? { carrierCommercialInvoice: commercialInvoice } : {}),
    },
    documents,
    errors: get(shipment, 'post_shipping_info.error_details'),

    pickupContactName: get(shipment, 'pickup.contact_name', ''),
    pickupTelephone: get(shipment, 'pickup.contact_phone', ''),
    pickupAlternatePhone: get(shipment, 'pickup.alternate_phone', ''),
    pickupEmail: get(shipment, 'pickup.contact_email', ''),
    pickupAddress1: get(shipment, 'pickup.address1'),
    pickupAddress2: get(shipment, 'pickup.address2'),
    pickupArea: get(shipment, 'pickup.area'),
    pickupState: get(shipment, 'pickup.state'),
    pickupLocationId: get(shipment, 'pickup.partner_location_id', ''),
    pickupCity: get(shipment, 'pickup.city'),
    pickupCountry: enums.getShortNameForCountry(get(shipment, 'pickup.country', '')),
    pickupCountryCode: get(shipment, 'pickup.country', ''),
    pickupPostcode: get(shipment, 'pickup.postcode'),
    pickupAddress: formatAddress(get(shipment, 'pickup')),
    pickupW3WAddress: get(shipment, 'pickup.what3words', '').replace(/^\/\/\//, ''),
    pickupCoordinates: get(shipment, 'pickup.coords'),
    pickupNotes: get(shipment, 'pickup.notes', ''),
    pickupPersonalId: get(shipment, 'pickup.personal_id.id'),
    pickupPersonalIdType: get(shipment, 'pickup.personal_id.type'),

    deliveryContactName: get(shipment, 'dropoff.contact_name', ''),
    deliveryTelephone: get(shipment, 'dropoff.contact_phone', ''),
    deliveryAlternatePhone: get(shipment, 'dropoff.alternate_phone', ''),
    deliveryEmail: get(shipment, 'dropoff.contact_email', ''),
    deliveryAddress1: get(shipment, 'dropoff.address1'),
    deliveryAddress2: get(shipment, 'dropoff.address2'),
    deliveryArea: get(shipment, 'dropoff.area'),
    deliveryLocationId: get(shipment, 'dropoff.partner_location_id', ''),
    deliveryState: get(shipment, 'dropoff.state'),
    deliveryCity: get(shipment, 'dropoff.city'),
    deliveryCountry: enums.getShortNameForCountry(get(shipment, 'dropoff.country', '')),
    deliveryCountryCode: get(shipment, 'dropoff.country', ''),
    deliveryPostcode: get(shipment, 'dropoff.postcode'),
    deliveryAddress: formatAddress(get(shipment, 'dropoff')),
    deliveryCoordinates: get(shipment, 'dropoff.coords'),
    deliveryW3WAddress: get(shipment, 'dropoff.what3words', '').replace(/^\/\/\//, ''),
    deliveryCollectionPointId: get(shipment, 'dropoff.collection_point_id'),
    deliveryPersonalId: get(shipment, 'dropoff.personal_id.id'),
    deliveryPersonalIdType: get(shipment, 'dropoff.personal_id.type'),
    deliveryNotes: get(shipment, 'dropoff.notes', ''),

    customAttributes: get(shipment, 'custom_attributes') || undefined,

    products: get(shipment, 'items', []).map(({
      sku = '',
      description = '',
      quantity = '',
      price: {
        amount = '',
        currency = '',
      } = {},
      battery = {},
      weight,
      hs_code: hsCode,
      barcode,
      image_link: imageLink,
      origin_country: originCountry,
      dangerous_goods: dangerousGoods,
    }, index) => {
      const weightValue = toNumber(weight?.value) || 0;
      const weightUnit = weight?.unit || '';
      const hasWeight = weightValue !== 0;
      return {
        number: index + 1,
        sku,
        description,
        quantity,
        unitPrice: `${amount} ${currency}`,
        weight: hasWeight ? `${weightValue} ${weightUnit}` : '',
        hsCode,
        barcode,
        imageLink,
        originCountry,
        dangerousGoods,
        battery: camelCaseKeys(battery),
      };
    }),
    parcels: get(shipment, 'parcels', [])
      .map(({
        dimension,
        weight,
        partner_parcel_reference,
      } = {}, index) => {
        const width = toNumber(dimension?.width) || 0;
        const height = toNumber(dimension?.height) || 0;
        const depth = toNumber(dimension?.depth) || 0;
        const dimensionUnit = dimension?.unit || '';
        const weightValue = toNumber(weight?.value) || 0;
        const weightUnit = weight?.unit || '';
        const hasDimension = [width, height, depth].some((val) => val > 0);
        const hasWeight = weightValue !== 0;
        return {
          number: index + 1,
          weight: hasWeight ? `${weightValue} ${weightUnit}` : '',
          dimension: hasDimension ? `${width} x ${height} x ${depth} ${dimensionUnit}` : '',
          partnerReference: partner_parcel_reference,
        };
      }),

    estimatedProcessDate: getLocalMoment(get(shipment, 'estimated_process_date')),
    estimatedShipDate: getLocalMoment(get(shipment, 'post_shipping_info.estimated_ship_date')),
    estimatedDeliveryDate: getLocalMoment(get(shipment, 'post_shipping_info.estimated_delivery_date')),
    promisedDeliveryDate: getLocalMoment(get(shipment, 'promised_delivery_date')),

    milestones: get(shipment, 'post_shipping_info.key_milestones', {}),
    labelStatus: get(shipment, 'post_shipping_info.async_statuses.label', ''),
    preBooked: get(shipment, 'pre_booked', false),
  };
};

export const fromReverseShipment = (shipment = {}) => {
  const status = get(shipment, 'post_shipping_info.status', '');

  const concatFields = ({
    namespace,
    fields,
    delimiter = ', ',
  }) => fields
    .map((field) => get(shipment, [...namespace, field], ''))
    .filter(Boolean)
    .join(delimiter);

  return {
    steps: getSteps(shipment),

    schedulePickupFrom: get(shipment, 'collection.scheduled_from', null),
    schedulePickupTo: get(shipment, 'collection.scheduled_to', null),
    scheduleDropoffFrom: get(shipment, 'delivery.scheduled_from', null),
    scheduleDropoffTo: get(shipment, 'delivery.scheduled_to', null),
    entityType: get(shipment, 'entity_type', 'REVERSE'),

    orderRef: get(shipment, 'references.partner_order_reference', ''),
    shipmentRef: get(shipment, 'references.partner_shipment_reference', ''),
    alternateRef: get(shipment, 'references.alternate_reference', ''),
    shipmentId: get(shipment, 'shipment_id', ''),
    merchant: get(shipment, 'merchant', ''),
    orderDate: formatDate(get(shipment, 'order_date', '')),
    createdAt: formatDate(get(shipment, 'creation_date', '')),
    updatedAt: formatDate(get(shipment, 'update_date', '')),
    confirmationDate: formatDate(get(shipment, 'confirmation_date', '')),
    status,
    lastStatusRefreshed: isoTimestamp(
      get(shipment, 'post_shipping_info.last_status_refresh_date'),
    ),
    customerKey: get(shipment, 'customer_key', ''),
    customerName: get(shipment, [shipment.entity_type === 'FORWARD' ? 'dropoff' : 'pickup', 'contact_name'], ''),
    orderValue: concatFields({
      namespace: ['payment'],
      fields: ['total_amount', 'currency'],
      delimiter: ' ',
    }),

    carrierName: get(shipment, 'carrier_account.carrier', ''),
    carrierAccountName: get(shipment, 'carrier_account.carrier_account_name'),
    carrierId: get(shipment, 'carrier_account.carrier_id', ''),
    carrierStatus: get(shipment, 'post_shipping_info.carrier_status', ''),
    carrierStatusDescription: get(shipment, 'post_shipping_info.carrier_status_description', ''),
    carrierTrackingNumber: get(shipment, 'post_shipping_info.tracking_no', ''),
    documentURLs: {
      default: get(shipment, 'post_shipping_info.default_label_url', ''),
      carriyoPDF: get(shipment, 'post_shipping_info.carriyo_pdf_label_url', ''),
      carriyoZPL: get(shipment, 'post_shipping_info.carriyo_zpl_label_url', ''),
      commercialInvoice: get(shipment, 'post_shipping_info.commercial_invoice', ''),
      carriyoCommercialInvoice: get(shipment, 'post_shipping_info.carriyo_pdf_commercial_invoice_url', ''),
    },
    errors: get(shipment, 'post_shipping_info.error_details'),
    language: get(shipment, 'language'),

    pickupContactName: get(shipment, 'pickup.contact_name', ''),
    pickupTelephone: get(shipment, 'pickup.contact_phone', ''),
    pickupAlternatePhone: get(shipment, 'pickup.alternate_phone', ''),
    pickupEmail: get(shipment, 'pickup.contact_email', ''),
    pickupAddress: formatAddress(get(shipment, 'pickup')),
    pickupAddress1: get(shipment, 'pickup.address1'),
    pickupAddress2: get(shipment, 'pickup.address2'),
    pickupArea: get(shipment, 'pickup.area'),
    pickupState: get(shipment, 'pickup.state'),
    pickupCity: get(shipment, 'pickup.city'),
    pickupCountry: enums.getShortNameForCountry(get(shipment, 'pickup.country', '')),
    pickupCountryCode: get(shipment, 'pickup.country', ''),
    pickupPostcode: get(shipment, 'pickup.postcode'),
    pickupCoordinates: get(shipment, 'pickup.coords'),
    pickupW3WAddress: get(shipment, 'pickup.what3words', '').replace(/^\/\/\//, ''),
    pickupNotes: get(shipment, 'pickup.notes', ''),
    pickupPersonalId: get(shipment, 'pickup.personal_id.id'),
    pickupPersonalIdType: get(shipment, 'pickup.personal_id.type'),

    deliveryContactName: get(shipment, 'dropoff.contact_name', ''),
    deliveryTelephone: get(shipment, 'dropoff.contact_phone', ''),
    deliveryAlternatePhone: get(shipment, 'dropoff.alternate_phone', ''),
    deliveryEmail: get(shipment, 'dropoff.contact_email', ''),
    deliveryAddress: formatAddress(get(shipment, 'dropoff')),
    deliveryAddress1: get(shipment, 'dropoff.address1'),
    deliveryAddress2: get(shipment, 'dropoff.address2'),
    deliveryArea: get(shipment, 'dropoff.area'),
    deliveryState: get(shipment, 'dropoff.state'),
    deliveryLocationId: get(shipment, 'dropoff.partner_location_id'),
    deliveryCity: get(shipment, 'dropoff.city'),
    deliveryCountry: enums.getShortNameForCountry(get(shipment, 'dropoff.country', '')),
    deliveryCountryCode: get(shipment, 'dropoff.country', ''),
    deliveryPostcode: get(shipment, 'dropoff.postcode'),
    deliveryCoordinates: get(shipment, 'dropoff.coords'),
    deliveryW3WAddress: get(shipment, 'dropoff.what3words', '').replace(/^\/\/\//, ''),
    deliveryCollectionPointId: get(shipment, 'dropoff.collection_point_id'),
    deliveryPersonalId: get(shipment, 'dropoff.personal_id.id'),
    deliveryPersonalIdType: get(shipment, 'dropoff.personal_id.type'),
    deliveryNotes: get(shipment, 'dropoff.notes', ''),

    customAttributes: get(shipment, 'custom_attributes') || undefined,

    products: get(shipment, 'items', []).map(({
      sku = '',
      description = '',
      quantity = '',
      price: {
        amount = '',
        currency = '',
      } = {},
      weight,
      hs_code: hsCode,
      barcode,
      origin_country: originCountry,
      dangerous_goods: dangerousGoods,
    }, index) => {
      const weightValue = toNumber(weight?.value) || 0;
      const weightUnit = weight?.unit || '';
      const hasWeight = weightValue !== 0;
      return {
        number: index + 1,
        sku,
        description,
        quantity,
        unitPrice: `${amount} ${currency}`,
        weight: hasWeight ? `${weightValue} ${weightUnit}` : '',
        hsCode,
        barcode,
        originCountry,
        dangerousGoods,
      };
    }),
    parcels: (shipment.parcels || [])
      .map(({
        dimension,
        weight,
        partner_parcel_reference,
      } = {}, index) => {
        const width = toNumber(dimension?.width) || 0;
        const height = toNumber(dimension?.height) || 0;
        const depth = toNumber(dimension?.depth) || 0;
        const dimensionUnit = dimension?.unit || '';
        const weightValue = toNumber(weight?.value) || 0;
        const weightUnit = weight?.unit || '';
        const hasDimension = [width, height, depth].some((val) => val > 0);
        const hasWeight = weightValue !== 0;
        return {
          number: index + 1,
          weight: hasWeight ? `${weightValue} ${weightUnit}` : '',
          dimension: hasDimension ? `${width} x ${height} x ${depth} ${dimensionUnit}` : '',
          partnerReference: partner_parcel_reference,
        };
      }),

    estimatedProcessDate: getLocalMoment(get(shipment, 'estimated_process_date')),
    estimatedShipDate: getLocalMoment(get(shipment, 'post_shipping_info.estimated_ship_date')),
    estimatedDeliveryDate: getLocalMoment(get(shipment, 'post_shipping_info.estimated_delivery_date')),
    promisedDeliveryDate: getLocalMoment(get(shipment, 'promised_delivery_date')),

    milestones: get(shipment, 'post_shipping_info.key_milestones', {}),
    labelStatus: get(shipment, 'post_shipping_info.async_statuses.label', ''),
    preBooked: get(shipment, 'pre_booked', false),
  };
};

export const createReverseShipmentPayloadFromForwardShipment = (rawForwardShipment) => {
  const {
    pickup,
    dropoff,
    references: {
      // ignore because partner_shipment_reference cannot have duplicates (across entity types)
      // and there is no UI currently to change partner_shipment_reference
      partner_shipment_reference,
      ...references
    } = {},
    payment: {
      // ignore because not relevant to reverse shipment
      pending_amount,
      payment_mode,
      ...payment
    },

    // stuff to ignore
    // ignore because new shipment should re-create these
    shipment_id,
    creation_date,
    update_date,
    // reset carrier because we want auto carrier selection as default
    carrier_account,
    post_shipping_info,
    // ignore because not relevant to reverse shipment
    entity_type,
    order_date,
    confirmation_date,
    estimated_process_date,
    promised_delivery_date,
    delivery,
    custom_attributes,

    ...allOthers
  } = rawForwardShipment;

  return {
    ...allOthers,
    entity_type: 'REVERSE',
    pickup: dropoff,
    dropoff: pickup,
    references,
    payment: {
      ...payment,
      // makes sense to be default as no company does cash returns
      payment_mode: 'PRE_PAID',
    },
    carrier_account: {},
  };
};

export const fromReturnRequest = (returnRequest = {}) => {
  const status = get(returnRequest, 'status', '');

  const productAdditionalInfo = keyBy(get(returnRequest, 'return_items_updates', []), 'sku');
  const items = get(returnRequest, 'items', []).map(({
    sku = '',
    requested_quantity: requestedQuantity = 0,
    approved_quantity: approvedQuantity = 0,
    returned_quantity: returnedQuantity = 0,
    received_quantity: receivedQuantity = 0,
    return_reason: returnReason,
    rejection_reason: rejectionReason,
    dropoff,
  }, index) => {
    const moreInfo = productAdditionalInfo[sku];
    return {
      number: index + 1,
      sku,
      description: moreInfo?.description || '',
      unitPrice: moreInfo?.price?.amount !== undefined
        ? `${moreInfo.price.amount} ${moreInfo.price.currency || ''}`.trim()
        : '',
      currency: moreInfo?.price?.currency,
      requestedQuantity,
      approvedQuantity,
      returnedQuantity,
      receivedQuantity,
      returnReason,
      rejectionReason,
      dropoff: {
        ...camelCaseKeys(dropoff || {}),
        address: formatAddress(dropoff),
      },
    };
  });

  return {
    steps: getReturnRequestSteps(returnRequest),

    returnRequestId: get(returnRequest, 'return_request_id', ''),
    merchant: get(returnRequest, 'merchant', ''),
    orderRef: get(returnRequest, 'partner_order_reference', ''),
    reverseShipmentIds: get(returnRequest, 'reverse_shipments', []),
    createdAt: formatDate(get(returnRequest, 'creation_date', '')),
    updatedAt: formatDate(get(returnRequest, 'update_date', '')),

    approvedAt: formatDate(get(returnRequest, 'approval_date', '')),
    rejectedAt: formatDate(get(returnRequest, 'rejection_date', '')),
    completedAt: formatDate(get(returnRequest, 'completion_date', '')),
    cancelledAt: formatDate(get(returnRequest, 'cancellation_date', '')),

    channel: get(returnRequest, 'channel', ''),
    customerComment: get(returnRequest, 'customer_comment', ''),
    resolution: get(returnRequest, 'resolution', ''),

    status,

    pickupLocationId: get(returnRequest, 'pickup.partner_location_id'),
    pickupContactName: get(returnRequest, 'pickup.contact_name', ''),
    pickupTelephone: get(returnRequest, 'pickup.contact_phone', ''),
    alternatePhone: get(returnRequest, 'pickup.alternate_phone', ''),
    pickupEmail: get(returnRequest, 'pickup.contact_email', ''),
    pickupAddress: formatAddress(get(returnRequest, 'pickup')),
    pickupAddress1: get(returnRequest, 'pickup.address1'),
    pickupAddress2: get(returnRequest, 'pickup.address2'),
    pickupState: get(returnRequest, 'pickup.state'),
    pickupCity: get(returnRequest, 'pickup.city'),
    pickupCountry: enums.getShortNameForCountry(get(returnRequest, 'pickup.country', '')),
    pickupPostcode: get(returnRequest, 'pickup.postcode'),
    pickupCoordinates: get(returnRequest, 'pickup.coords'),
    pickupType: get(returnRequest, 'pickup.type'),
    pickupPersonalId: get(returnRequest, 'pickup.personal_id.id'),
    pickupPersonalIdType: get(returnRequest, 'pickup.personal_id.type'),

    items,
    refunds: camelCaseKeys(get(returnRequest, 'refund_info.refunds')),
    notes: returnRequest.notes,
  };
};

// Correct possible incorrect data structures
/**
 * @param {CountryStateAndCityTree} locationTree
 * @returns {CountryStateAndCityTree}
 */
const correctLocationTree = (locationTree) => camelCaseKeys(locationTree || [])
  .map(({ country, stateCities }) => {
    if (country === '_ANY') {
      return {
        country: '_ANY',
        stateCities: [{ state: '_ANY', cities: ['_ANY'] }],
      };
    }
    // NOTE: _ANY actually means "other". But UI cannot handle this for states
    if (find(stateCities, { state: '_ANY' })) {
      return {
        country,
        stateCities: [{ state: '_ANY', cities: ['_ANY'] }],
      };
    }
    return {
      country,
      stateCities: map(stateCities, ({ state, cities }) => ({
        state,
        // ensure that if _ANY city exists no other values exists
        cities: cities.includes('_ANY') ? ['_ANY'] : cities,
      })),
    };
  });

/**
 * Remove empty countries
 * @param {CountryStateAndCityTree} locationTree
 * @returns {CountryStateAndCityTree}
 */
const trimLocationTree = (locationTree) => (locationTree || [])
  .filter(({ stateCities }) => stateCities?.length > 0);

export const fromRule = (rule = {}, { locations }) => {
  const {
    rule_id,
    entity_type,
    default_rule,
    merchant = null,
    status,
    rule_name,
    description,
    sequence,
    carrier_id,
    carrier,
    start_time,
    end_time,
    days,
    rule_order_limit,
    order_value_min,
    order_value_max,
    dangerous_goods,
  } = rule || {};

  // remove null
  const dangerousGoods = dangerous_goods ?? undefined;

  const {
    counter_scope: counterScope,
    threshold,
  } = rule_order_limit || {};

  const {
    delivery_type: {
      operator: deliveryTypeOperator,
      value: deliveryTypes,
    },
    payment_type: {
      operator: paymentTypeOperator,
      value: paymentTypes,
    },
    pickup: {
      operator: pickupOperator,
      value: pickupValue,
    },
    dropoff: {
      operator: dropoffOperator,
      value: dropoffValue,
    },
    pickup_partner_location_ids: {
      operator: pickupPartnerLocationOperator,
      value: pickupLocations,
    },
    dropoff_partner_location_ids: {
      operator: dropoffPartnerLocationOperator,
      value: dropoffLocations,
    },
    custom_conditions: {
      operator: customConditionOperator,
      value: customConditionValue,
    },
  } = [
    'delivery_type',
    'payment_type',
    'pickup',
    'dropoff',
    'pickup_partner_location_ids',
    'dropoff_partner_location_ids',
    'custom_conditions',
  ].reduce((acc, prop) => ({
    ...acc,
    [prop]: {
      operator: rule[prop]?.operator || '',
      value: rule[prop]?.value || [],
    },
  }), {});

  // Correct possible incorrect data structures
  const pickupFromRule = correctLocationTree(pickupValue);
  const dropoffFromRule = correctLocationTree(dropoffValue);

  // FIXME: display logic (label) shouldn't be here.
  const pickupLocationOptions = map(pickupLocations, (pickupLocation) => {
    const location = find(locations, { locationId: pickupLocation || '' });
    if (location) return { label: location.locationName, value: pickupLocation };
    return { label: pickupLocation, value: pickupLocation };
  });
  // FIXME: display logic (label) shouldn't be here.
  const dropoffLocationOptions = map(dropoffLocations, (dropoffLocation) => {
    const location = find(locations, { locationId: dropoffLocation || '' });
    if (location) return { label: location.locationName, value: dropoffLocation };
    return { label: dropoffLocation, value: dropoffLocation };
  });

  return {
    rowId: rule_id,
    ruleId: rule_id,
    entityType: entity_type,
    defaultRule: default_rule || false,
    merchant,
    active: status === 'ACTIVE',
    name: rule_name || '',
    description: description || '',
    priority: sequence || 0,
    carrierId: carrier_id,
    carrierName: carrier,
    startTime: start_time || '00:00',
    endTime: end_time || '00:00',
    days: days || [],
    // conditions
    orderLimit: {
      counterScope,
      threshold,
    },
    orderValue: {
      from: order_value_min,
      to: order_value_max,
    },
    deliveryTypes,
    paymentTypes,
    dangerousGoods,
    dropoff: camelCaseKeys(dropoffFromRule),
    pickup: camelCaseKeys(pickupFromRule),
    pickupLocations: pickupLocationOptions,
    dropoffLocations: dropoffLocationOptions,
    customConditionOperator,
    customConditions: (customConditionValue || []).map(({
      attribute_name, operation, values,
    }) => ({
      attributeName: attribute_name,
      operation,
      values,
    })),
    ...((customConditionValue || []).reduce(
      (acc, { attribute_name, operation, values }) => ({
        ...acc,
        [camelCase(`customAttr-${attribute_name}`)]: {
          attributeName: attribute_name,
          operation,
          values,
        },
      }),
      {},
    )),
    conditions: [
      ...(threshold > 0 ? [{ condition: 'orderLimit', operator: '' }] : []),
      ...((order_value_min > 0 || order_value_max !== 100000) ? [{ condition: 'orderValue', operator: '' }] : []),
      ...(deliveryTypes.length > 0 ? [{ condition: 'deliveryTypes', operator: deliveryTypeOperator }] : []),
      ...(paymentTypes.length > 0 ? [{ condition: 'paymentTypes', operator: paymentTypeOperator }] : []),
      ...(dangerousGoods !== undefined ? [{ condition: 'dangerousGoods', operator: '' }] : []),
      ...(pickupFromRule.length > 0 ? [{ condition: 'pickup', operator: pickupOperator }] : []),
      ...(dropoffFromRule.length > 0 ? [{ condition: 'dropoff', operator: dropoffOperator }] : []),
      ...(pickupLocationOptions.length ? [{ condition: 'pickupLocations', operator: pickupPartnerLocationOperator }] : []),
      ...(dropoffLocationOptions.length ? [{ condition: 'dropoffLocations', operator: dropoffPartnerLocationOperator }] : []),
      ...(customConditionValue?.length
        ? customConditionValue.map(({ attribute_name }) => ({
          condition: camelCase(`customAttr-${attribute_name}`),
          operator: customConditionOperator,
        }))
        : []
      ),
    ],
  };
};

export const toRule = (rule = {}) => {
  const {
    ruleId,
    entityType: entity_type,
    defaultRule,
    merchant = null,
    status = rule.active ? 'ACTIVE' : 'INACTIVE',
    name: rule_name,
    description,
    priority: sequence,
    carrierId,
    carrierName,
    startTime,
    endTime,
    days,
    orderLimit: {
      counterScope: counter_scope,
      threshold,
    },
    orderValue: {
      from: order_value_min,
      to: order_value_max,
    },
    deliveryTypes = [],
    paymentTypes = [],
    dangerousGoods: dangerous_goods,
    pickup = [],
    dropoff = [],
    pickupLocations = [],
    dropoffLocations = [],
    customConditions,
    customConditionOperator,
    conditions = [],
  } = rule;
  const [
    delivery_type,
    payment_type,
  ] = [
    deliveryTypes,
    paymentTypes,
  ].map((arr) => (arr.length === 0 ? null : arr));

  const [
    pickup_partner_location_ids,
    dropoff_partner_location_ids,
  ] = [
    pickupLocations,
    dropoffLocations,
  ].map((arr) => (arr.length === 0 ? null : arr.map(({ value }) => value)));

  return {
    rule_id: ruleId,
    entity_type,
    default_rule: defaultRule,
    merchant,
    status,
    rule_name,
    description,
    sequence,
    carrier_id: carrierId,
    carrier: carrierName,
    start_time: startTime,
    end_time: endTime,
    days,
    ...(find(conditions, { condition: 'orderLimit' }) ? {
      rule_order_limit: {
        counter_scope,
        threshold,
      },
    } : {}),
    ...(find(conditions, { condition: 'orderValue' }) ? {
      order_value_min,
      order_value_max,
    } : {
      order_value_min: 0,
      order_value_max: 100000,
    }),
    ...(find(conditions, { condition: 'deliveryTypes' }) ? {
      delivery_type: {
        operator: find(conditions, { condition: 'deliveryTypes' })?.operator || '',
        value: delivery_type,
      },
    } : {
      delivery_type: null,
    }),
    ...(find(conditions, { condition: 'paymentTypes' }) ? {
      payment_type: {
        operator: find(conditions, { condition: 'paymentTypes' })?.operator || '',
        value: payment_type,
      },
    } : {
      payment_type: null,
    }),
    ...(find(conditions, { condition: 'dangerousGoods' }) ? {
      dangerous_goods,
    } : {
      dangerous_goods: null,
    }),
    ...(find(conditions, { condition: 'pickup' }) ? {
      pickup: {
        operator: find(conditions, { condition: 'pickup' }).operator || '',
        value: snakeCaseKeys(trimLocationTree(pickup)),
      },
    } : {
      pickup: null,
    }),
    ...(find(conditions, { condition: 'dropoff' }) ? {
      dropoff: {
        operator: find(conditions, { condition: 'dropoff' }).operator || '',
        value: snakeCaseKeys(trimLocationTree(dropoff)),
      },
    } : {
      dropoff: null,
    }),
    ...(find(conditions, { condition: 'pickupLocations' }) ? {
      pickup_partner_location_ids: {
        operator: find(conditions, { condition: 'pickupLocations' })?.operator || '',
        value: pickup_partner_location_ids,
      },
    } : {
      pickup_partner_location_ids: null,
    }),
    ...(find(conditions, { condition: 'dropoffLocations' }) ? {
      dropoff_partner_location_ids: {
        operator: find(conditions, { condition: 'dropoffLocations' })?.operator || '',
        value: dropoff_partner_location_ids,
      },
    } : {
      dropoff_partner_location_ids: null,
    }),
    custom_conditions: customConditions?.length ? {
      operator: customConditionOperator,
      value: customConditions
        .map(({
          attributeName,
          operation,
          values,
        }) => ({
          attribute_name: attributeName,
          operation,
          values,
        })),
    } : null,
  };
};

export const fromCapacityRule = (rule = {}, { locations }) => {
  const {
    capacity_profile_id,
    capacity_id,
    name,
    entity_type,
    threshold,
    sequence,
    status,
    special_start_date,
    special_end_date,
    days,
  } = rule || {};

  const {
    pickup: {
      operator: pickupOperator,
      value: pickupValue,
    },
    dropoff: {
      operator: dropoffOperator,
      value: dropoffValue,
    },
    pickup_partner_location_ids: {
      operator: pickupPartnerLocationOperator,
      value: pickupLocations,
    },
    dropoff_partner_location_ids: {
      operator: dropoffPartnerLocationOperator,
      value: dropoffLocations,
    },
    custom_conditions: {
      operator: customConditionOperator,
      value: customConditionValue,
    },
  } = [
    'pickup',
    'dropoff',
    'pickup_partner_location_ids',
    'dropoff_partner_location_ids',
    'custom_conditions',
  ].reduce((acc, prop) => ({
    ...acc,
    [prop]: {
      operator: rule[prop]?.operator || '',
      value: rule[prop]?.value || [],
    },
  }), {});

  // Correct possible incorrect data structures
  const pickupFromRule = correctLocationTree(pickupValue);
  const dropoffFromRule = correctLocationTree(dropoffValue);

  // FIXME: display logic (label) shouldn't be here.
  const pickupLocationOptions = map(pickupLocations, (pickupLocation) => {
    const location = find(locations, { locationId: pickupLocation || '' });
    if (location) return { label: location.locationName, value: pickupLocation };
    return { label: pickupLocation, value: pickupLocation };
  });
  // FIXME: display logic (label) shouldn't be here.
  const dropoffLocationOptions = map(dropoffLocations, (dropoffLocation) => {
    const location = find(locations, { locationId: dropoffLocation || '' });
    if (location) return { label: location.locationName, value: dropoffLocation };
    return { label: dropoffLocation, value: dropoffLocation };
  });

  return {
    capacityProfileId: capacity_profile_id,
    capacityId: capacity_id,
    name,
    entityType: entity_type,
    threshold,
    sequence,
    status,
    specialStartDate: special_start_date,
    specialEndDate: special_end_date,
    days: days || [],
    // conditions
    dropoff: camelCaseKeys(dropoffFromRule),
    pickup: camelCaseKeys(pickupFromRule),
    pickupLocations: pickupLocationOptions,
    dropoffLocations: dropoffLocationOptions,
    customConditionOperator,
    customConditions: (customConditionValue || []).map(({
      attribute_name, operation, values,
    }) => ({
      attributeName: attribute_name,
      operation,
      values,
    })),
    ...((customConditionValue || []).reduce(
      (acc, { attribute_name, operation, values }) => ({
        ...acc,
        [camelCase(`customAttr-${attribute_name}`)]: {
          attributeName: attribute_name,
          operation,
          values,
        },
      }),
      {},
    )),
    conditions: [
      ...(pickupFromRule.length > 0 ? [{ condition: 'pickup', operator: pickupOperator }] : []),
      ...(dropoffFromRule.length > 0 ? [{ condition: 'dropoff', operator: dropoffOperator }] : []),
      ...(pickupLocationOptions.length ? [{ condition: 'pickupLocations', operator: pickupPartnerLocationOperator }] : []),
      ...(dropoffLocationOptions.length ? [{ condition: 'dropoffLocations', operator: dropoffPartnerLocationOperator }] : []),
      ...(customConditionValue?.length
        ? customConditionValue.map(({ attribute_name }) => ({
          condition: camelCase(`customAttr-${attribute_name}`),
          operator: customConditionOperator,
        }))
        : []
      ),
    ],
  };
};

export const toCapacityRule = (rule = {}) => {
  const {
    capacityProfileId,
    capacityId,
    entityType,
    name,
    threshold,
    sequence,
    status = rule.active ? 'ACTIVE' : 'INACTIVE',
    specialStartDate,
    specialEndDate,
    days,

    pickup = [],
    dropoff = [],
    pickupLocations = [],
    dropoffLocations = [],
    customConditions,
    customConditionOperator,
    conditions = [],
  } = rule;

  const [
    pickup_partner_location_ids,
    dropoff_partner_location_ids,
  ] = [
    pickupLocations,
    dropoffLocations,
  ].map((arr) => (arr.length === 0 ? null : arr.map(({ value }) => value)));

  return {
    capacity_profile_id: capacityProfileId,
    capacity_id: capacityId,
    name,
    entity_type: entityType,
    threshold,
    sequence,
    status,
    special_start_date: specialStartDate,
    special_end_date: specialEndDate,
    days,
    ...(find(conditions, { condition: 'pickup' }) ? {
      pickup: {
        operator: find(conditions, { condition: 'pickup' }).operator || '',
        value: snakeCaseKeys(trimLocationTree(pickup)),
      },
    } : {
      pickup: null,
    }),
    ...(find(conditions, { condition: 'dropoff' }) ? {
      dropoff: {
        operator: find(conditions, { condition: 'dropoff' }).operator || '',
        value: snakeCaseKeys(trimLocationTree(dropoff)),
      },
    } : {
      dropoff: null,
    }),
    ...(find(conditions, { condition: 'pickupLocations' }) ? {
      pickup_partner_location_ids: {
        operator: find(conditions, { condition: 'pickupLocations' })?.operator || '',
        value: pickup_partner_location_ids,
      },
    } : {
      pickup_partner_location_ids: null,
    }),
    ...(find(conditions, { condition: 'dropoffLocations' }) ? {
      dropoff_partner_location_ids: {
        operator: find(conditions, { condition: 'dropoffLocations' })?.operator || '',
        value: dropoff_partner_location_ids,
      },
    } : {
      dropoff_partner_location_ids: null,
    }),
    custom_conditions: customConditions?.length ? {
      operator: customConditionOperator,
      value: customConditions
        .map(({
          attributeName,
          operation,
          values,
        }) => ({
          attribute_name: attributeName,
          operation,
          values,
        })),
    } : null,
  };
};

export const fromCapacityProfile = (profile = {}) => {
  const { carrier_account_ids, ...otherProps } = profile;
  return {
    ...camelCaseKeys(otherProps),
    carrierIds: carrier_account_ids,
  };
};

export const toCapacityProfile = (profile = {}) => {
  const { carrierIds, ...otherProps } = profile;
  return {
    ...snakeCaseKeys(otherProps),
    carrier_account_ids: carrierIds,
  };
};

export const fromCarrier = ({
  carrier_account_id: carrierId,
  carrier: name,
  carrier_account_name: accountName,
  deleted,
  merchants = null,
  auto_ready_to_ship: autoReadyToShip,
  auto_translate_to_english: autoTranslateToEnglish,
  property,
  label,
  network,
  network_id: networkId,
  daily_capacity_id: dailyCapacityProfileId,
  in_flight_capacity_id: inFlightCapacityProfileId,
  parent = {},
  status,
}) => {
  const {
    generate_carriyo_label: generateCarriyoLabel,
    // override_carrier_label & label_format are only used by LV
    // no UI will be be created for this as of now.
    override_carrier_label: overrideCarrierLabel,
    label_format: labelFormat,
  } = label || {};

  const defaultLabel = Object
    .entries({
      carrier_label: !overrideCarrierLabel,
      carriyo_pdf_label: overrideCarrierLabel && labelFormat === 'PDF',
      carriyo_zpl_label: overrideCarrierLabel && labelFormat === 'ZPL',
    })
    .find(([, val]) => val === true)?.[0];
  return {
    carrierId,
    name,
    accountName,
    deleted,
    merchants: merchants?.includes?.('_ANY') ? null : merchants,
    autoReadyToShip,
    autoTranslateToEnglish,
    property,
    label: {
      generateCarriyoLabel,
      defaultLabel,
    },
    network,
    networkId,
    dailyCapacityProfileId,
    inFlightCapacityProfileId,
    parent: camelCaseKeys(parent),
    status,
  };
};

export const fromCarrierStats = ({
  last_booking_date: lastBookingDate,
  last_error_date: lastErrorDate,
  last_real_time_update_applied_date: lastRealTimeUpdateAppliedDate,
  last_real_time_update_received_date: lastRealTimeUpdateReceivedDate,
  last_sync_update_applied_date: lastSyncUpdateAppliedDate,
  last_sync_update_received_date: lastSyncUpdateReceivedDate,
  ...rest
}) => ({
  ...fromCarrier(rest),
  lastBookingDate,
  lastErrorDate,
  lastRealTimeUpdateAppliedDate,
  lastRealTimeUpdateReceivedDate,
  lastSyncUpdateAppliedDate,
  lastSyncUpdateReceivedDate,
});

export const toCarrier = ({
  carrierId,
  name,
  accountName,
  merchants = null,
  autoReadyToShip,
  autoTranslateToEnglish,
  property,
  label,
  network,
  networkId,
  dailyCapacityProfileId,
  inFlightCapacityProfileId,
}) => {
  const {
    generateCarriyoLabel,
    defaultLabel,
  } = label || {};
  return {
    carrier_account_id: carrierId,
    carrier: name,
    carrier_account_name: accountName,
    merchants: merchants === null ? ['_ANY'] : merchants,
    auto_ready_to_ship: autoReadyToShip,
    auto_translate_to_english: autoTranslateToEnglish,
    property,
    label: {
      generate_carriyo_label: generateCarriyoLabel,
      ...({
        carrier_label: { override_carrier_label: false },
        carriyo_pdf_label: { override_carrier_label: true, label_format: 'PDF' },
        carriyo_zpl_label: { override_carrier_label: true, label_format: 'ZPL' },
      }[defaultLabel || 'carrier_label']),
    },
    network: (Array.isArray(network) && network.length) ? network : null,
    network_id: networkId,
    daily_capacity_id: dailyCapacityProfileId,
    in_flight_capacity_id: inFlightCapacityProfileId,
  };
};

export const fromNetwork = ({
  network,
  carrier_account_ids,
  ...otherProps
}) => ({
  ...camelCaseKeys(otherProps),
  network: correctLocationTree(network),
  carrierIds: carrier_account_ids,
});

export const toNetwork = ({
  carrierIds,
  ...otherProps
}) => ({
  ...snakeCaseKeys(otherProps),
  carrier_account_ids: carrierIds,
});

export const fromCustomAttribute = ({
  custom_attribute_id,
  attribute_entity_type,
  merchants = null,
  attribute_name: name,
  attribute_type: type,
  allowed_values,
  indexed,
}) => ({
  customAttributeId: custom_attribute_id,
  attributeEntityType: attribute_entity_type,
  merchants,
  name,
  type,
  allowedValues: allowed_values,
  indexed,
});

export const toCustomAttribute = ({
  customAttributeId,
  attributeEntityType,
  merchants = null,
  name,
  type,
  allowedValues,
}) => ({
  custom_attribute_id: customAttributeId,
  attribute_entity_type: attributeEntityType,
  merchants,
  attribute_name: name,
  attribute_type: type,
  allowed_values: allowedValues,
});

export const fromServiceLevelsConfig = (serviceLevels = {}, { locations }) => {
  const {
    rule_id,
    entity_type,
    config_type,
    merchant = null,
    status,
    name,
    description,
    sequence,
    start_time,
    end_time,
    lead_time,
    days,
  } = serviceLevels;

  // remove null
  const {
    carrier_ids: {
      operator: carrierIdsOperator,
      value: carrierIds,
    },
    delivery_types: {
      operator: deliveryTypesOperator,
      value: deliveryTypes,
    },
    custom_conditions: {
      operator: customConditionOperator,
      value: customConditionValue,
    },
    dropoff: {
      operator: dropoffOperator,
      value: dropoffValue,
    },
    pickup: {
      operator: pickupOperator,
      value: pickupValue,
    },
    pickup_partner_location_ids: {
      operator: pickupPartnerLocationOperator,
      value: pickupLocations,
    },
    dropoff_partner_location_ids: {
      operator: dropoffPartnerLocationOperator,
      value: dropoffLocations,
    },
  } = [
    'carrier_ids',
    'delivery_types',
    'custom_conditions',
    'dropoff',
    'pickup',
    'pickup_partner_location_ids',
    'dropoff_partner_location_ids',
  ].reduce((acc, prop) => ({
    ...acc,
    [prop]: {
      operator: serviceLevels[prop]?.operator || '',
      value: serviceLevels[prop]?.value || [],
    },
  }), {});

  // Correct possible incorrect data structures
  const pickupFromRule = correctLocationTree(pickupValue);
  const dropoffFromRule = correctLocationTree(dropoffValue);

  const pickupLocationOptions = map(pickupLocations, (pickupLocation) => {
    const location = find(locations, { locationId: pickupLocation || '' });
    if (location) return { label: location.locationName, value: pickupLocation };
    return { label: pickupLocation, value: pickupLocation };
  });
  // FIXME: display logic (label) shouldn't be here.
  const dropoffLocationOptions = map(dropoffLocations, (dropoffLocation) => {
    const location = find(locations, { locationId: dropoffLocation || '' });
    if (location) return { label: location.locationName, value: dropoffLocation };
    return { label: dropoffLocation, value: dropoffLocation };
  });

  return {
    configId: rule_id,
    entityType: entity_type,
    configType: config_type,
    merchant,
    active: status === 'ACTIVE',
    name: name || '',
    description: description || '',
    sequence: sequence || 0,
    days: days || [],
    leadTime: lead_time,
    startTime: start_time || '00:00',
    endTime: end_time || '00:00',
    // conditions
    carrierIds,
    deliveryTypes,
    dropoff: camelCaseKeys(dropoffFromRule),
    pickup: camelCaseKeys(pickupFromRule),
    pickupLocations: pickupLocationOptions,
    dropoffLocations: dropoffLocationOptions,
    customConditionOperator,
    customConditions: (customConditionValue || []).map(({
      attribute_name, operation, values,
    }) => ({
      attributeName: attribute_name,
      operation,
      values,
    })),
    ...((customConditionValue || []).reduce(
      (acc, { attribute_name, operation, values }) => ({
        ...acc,
        [camelCase(`customAttr-${attribute_name}`)]: {
          attributeName: attribute_name,
          operation,
          values,
        },
      }),
      {},
    )),
    conditions: [
      ...(deliveryTypes.length > 0 ? [{ condition: 'deliveryTypes', operator: deliveryTypesOperator }] : []),
      ...(carrierIds.length > 0 ? [{ condition: 'carrierIds', operator: carrierIdsOperator }] : []),
      ...(pickupLocationOptions.length ? [{ condition: 'pickupLocations', operator: pickupPartnerLocationOperator }] : []),
      ...(dropoffLocationOptions.length ? [{ condition: 'dropoffLocations', operator: dropoffPartnerLocationOperator }] : []),
      ...(pickupFromRule.length > 0 ? [{ condition: 'pickup', operator: pickupOperator }] : []),
      ...(dropoffFromRule.length > 0 ? [{ condition: 'dropoff', operator: dropoffOperator }] : []),
      ...(customConditionValue?.length
        ? customConditionValue.map(({ attribute_name }) => ({
          condition: camelCase(`customAttr-${attribute_name}`),
          operator: customConditionOperator,
        }))
        : []
      ),
    ],
  };
};

export const toServiceLevelsConfig = (serviceLevels = {}) => {
  const {
    configId: rule_id,
    entityType: entity_type = 'SHIPMENT',
    configType: config_type,
    merchant = null,
    status = serviceLevels.active ? 'ACTIVE' : 'INACTIVE',
    name,
    description,
    sequence,
    startTime,
    endTime,
    leadTime: lead_time,
    days,
    carrierIds = [],
    deliveryTypes = [],
    pickup = [],
    dropoff = [],
    pickupLocations = [],
    dropoffLocations = [],
    customConditionOperator,
    customConditions,
    conditions = [],
  } = serviceLevels;

  const [
    carrier_ids,
    delivery_types,
  ] = [
    carrierIds,
    deliveryTypes,
  ].map((arr) => (arr.length === 0 ? null : arr));

  const [
    pickup_partner_location_ids,
    dropoff_partner_location_ids,
  ] = [
    pickupLocations,
    dropoffLocations,
  ].map((arr) => (arr.length === 0 ? null : arr.map(({ value }) => value)));

  return {
    rule_id,
    entity_type,
    config_type,
    merchant,
    status,
    name,
    description,
    sequence,
    start_time: startTime,
    end_time: endTime,
    lead_time,
    days,
    ...(find(conditions, { condition: 'carrierIds' }) ? {
      carrier_ids: {
        operator: find(conditions, { condition: 'carrierIds' })?.operator || '',
        value: carrier_ids,
      },
    } : {
      carrier_ids: null,
    }),
    ...(find(conditions, { condition: 'deliveryTypes' }) ? {
      delivery_types: {
        operator: find(conditions, { condition: 'deliveryTypes' })?.operator || '',
        value: delivery_types,
      },
    } : {
      delivery_types: null,
    }),
    ...(find(conditions, { condition: 'pickup' }) ? {
      pickup: {
        operator: find(conditions, { condition: 'pickup' }).operator || '',
        value: snakeCaseKeys(trimLocationTree(pickup)),
      },
    } : {
      pickup: null,
    }),
    ...(find(conditions, { condition: 'dropoff' }) ? {
      dropoff: {
        operator: find(conditions, { condition: 'dropoff' }).operator || '',
        value: snakeCaseKeys(trimLocationTree(dropoff)),
      },
    } : {
      dropoff: null,
    }),
    ...(find(conditions, { condition: 'pickupLocations' }) ? {
      pickup_partner_location_ids: {
        operator: find(conditions, { condition: 'pickupLocations' })?.operator || '',
        value: pickup_partner_location_ids,
      },
    } : {
      pickup_partner_location_ids: null,
    }),
    ...(find(conditions, { condition: 'dropoffLocations' }) ? {
      dropoff_partner_location_ids: {
        operator: find(conditions, { condition: 'dropoffLocations' })?.operator || '',
        value: dropoff_partner_location_ids,
      },
    } : {
      dropoff_partner_location_ids: null,
    }),
    custom_conditions: customConditions?.length ? {
      operator: customConditionOperator,
      value: customConditions
        .map(({
          attributeName,
          operation,
          values,
        }) => ({
          attribute_name: attributeName,
          operation,
          values,
        })),
    } : null,
  };
};

export const fromWebhookConfig = (config, { locations }) => {
  const {
    headers,
    pickup,
    dropoff,
    pickup_partner_location_ids,
    dropoff_partner_location_ids,
    custom_conditions,
    ...otherProps
  } = config;
  [
    'notify_status',
  ].forEach((prop) => {
    otherProps[prop] = otherProps[prop] || [];
  });
  const {
    pickup: {
      operator: pickupOperator,
      value: pickupValue,
    },
    dropoff: {
      operator: dropoffOperator,
      value: dropoffValue,
    },
    pickup_partner_location_ids: {
      operator: pickupPartnerLocationOperator,
      value: pickupLocations,
    },
    dropoff_partner_location_ids: {
      operator: dropoffPartnerLocationOperator,
      value: dropoffLocations,
    },
    custom_conditions: {
      operator: customConditionOperator,
      value: customConditionValue,
    },
  } = [
    'pickup',
    'dropoff',
    'pickup_partner_location_ids',
    'dropoff_partner_location_ids',
    'custom_conditions',
  ].reduce((acc, prop) => ({
    ...acc,
    [prop]: {
      operator: config[prop]?.operator || '',
      value: config[prop]?.value || [],
    },
  }), {});

  // Correct possible incorrect data structures
  const pickupFromWebhook = correctLocationTree(pickupValue);
  const dropoffFromWebhook = correctLocationTree(dropoffValue);

  // FIXME: display logic (label) shouldn't be here.
  const pickupLocationOptions = map(pickupLocations, (pickupLocation) => {
    const location = find(locations, { locationId: pickupLocation || '' });
    if (location) return { label: location.locationName, value: pickupLocation };
    return { label: pickupLocation, value: pickupLocation };
  });
  // FIXME: display logic (label) shouldn't be here.
  const dropoffLocationOptions = map(dropoffLocations, (dropoffLocation) => {
    const location = find(locations, { locationId: dropoffLocation || '' });
    if (location) return { label: location.locationName, value: dropoffLocation };
    return { label: dropoffLocation, value: dropoffLocation };
  });

  return {
    headers,
    // conditions
    dropoff: camelCaseKeys(dropoffFromWebhook),
    pickup: camelCaseKeys(pickupFromWebhook),
    pickupLocations: pickupLocationOptions,
    dropoffLocations: dropoffLocationOptions,
    customConditionOperator,
    customConditions: (customConditionValue || []).map(({
      attribute_name, operation, values,
    }) => ({
      attributeName: attribute_name,
      operation,
      values,
    })),
    ...((customConditionValue || []).reduce(
      (acc, { attribute_name, operation, values }) => ({
        ...acc,
        [camelCase(`customAttr-${attribute_name}`)]: {
          attributeName: attribute_name,
          operation,
          values,
        },
      }),
      {},
    )),
    conditions: [
      ...(pickupFromWebhook.length > 0 ? [{ condition: 'pickup', operator: pickupOperator }] : []),
      ...(dropoffFromWebhook.length > 0 ? [{ condition: 'dropoff', operator: dropoffOperator }] : []),
      ...(pickupLocationOptions.length ? [{ condition: 'pickupLocations', operator: pickupPartnerLocationOperator }] : []),
      ...(dropoffLocationOptions.length ? [{ condition: 'dropoffLocations', operator: dropoffPartnerLocationOperator }] : []),
      ...(customConditionValue?.length
        ? customConditionValue.map(({ attribute_name }) => ({
          condition: camelCase(`customAttr-${attribute_name}`),
          operator: customConditionOperator,
        }))
        : []
      ),
    ],
    ...camelCaseKeys(otherProps),
  };
};

export const toWebhookConfig = (config) => {
  const {
    headers,
    pickup = [],
    dropoff = [],
    pickupLocations = [],
    dropoffLocations = [],
    customConditions,
    customConditionOperator,
    conditions = [],
    ...otherProps
  } = config;
  const [
    pickup_partner_location_ids,
    dropoff_partner_location_ids,
  ] = [
    pickupLocations,
    dropoffLocations,
  ].map((arr) => (arr.length === 0 ? null : arr.map(({ value }) => value)));
  [
    'notifyStatus',
  ].forEach((prop) => {
    if (Array.isArray(otherProps[prop]) && !otherProps[prop].length) {
      otherProps[prop] = null;
    }
  });
  return {
    headers,
    ...(find(conditions, { condition: 'pickup' }) ? {
      pickup: {
        operator: find(conditions, { condition: 'pickup' }).operator || '',
        value: snakeCaseKeys(trimLocationTree(pickup)),
      },
    } : {
      pickup: null,
    }),
    ...(find(conditions, { condition: 'dropoff' }) ? {
      dropoff: {
        operator: find(conditions, { condition: 'dropoff' }).operator || '',
        value: snakeCaseKeys(trimLocationTree(dropoff)),
      },
    } : {
      dropoff: null,
    }),
    ...(find(conditions, { condition: 'pickupLocations' }) ? {
      pickup_partner_location_ids: {
        operator: find(conditions, { condition: 'pickupLocations' })?.operator || '',
        value: pickup_partner_location_ids,
      },
    } : {
      pickup_partner_location_ids: null,
    }),
    ...(find(conditions, { condition: 'dropoffLocations' }) ? {
      dropoff_partner_location_ids: {
        operator: find(conditions, { condition: 'dropoffLocations' })?.operator || '',
        value: dropoff_partner_location_ids,
      },
    } : {
      dropoff_partner_location_ids: null,
    }),
    custom_conditions: customConditions?.length ? {
      operator: customConditionOperator,
      value: customConditions
        .map(({
          attributeName,
          operation,
          values,
        }) => ({
          attribute_name: attributeName,
          operation,
          values,
        })),
    } : null,
    ...snakeCaseKeys(otherProps),
  };
};

export const fromDeliveryType = ({ merchants = null, ...rest }) => ({
  merchants,
  ...camelCaseKeys(rest),
});

export const toDeliveryType = ({ merchants = null, ...rest }) => ({
  merchants,
  ...snakeCaseKeys(rest),
});

/**
 * @param {Object} obj
 * @returns {PartnerLocation}
 */
export const fromLocation = ({ merchants = null, ...rest }) => ({
  merchants: merchants?.includes?.('_ANY') ? null : merchants,
  ...camelCaseKeys(rest),
});

/**
 * @param {PartnerLocation} obj
 * @returns {Object}
 */
export const toLocation = ({
  merchants = null,
  address1,
  address2,
  locationCode,
  ...rest
}) => ({
  ...snakeCaseKeys(rest),
  merchants: merchants === null ? ['_ANY'] : merchants,
  // lodash's snake case for address1 is address_1.. but backend model keeps it as address1
  address1,
  address2,
  location_code: locationCode || undefined,
});

export const fromCity = ({
  city_code: cityCode,
  city_names: cityNames = [],
} = {}) => ({
  label: (cityNames.find(({ language }) => language === 'en') || {}).name || cityCode,
  value: cityCode,
});

export const fromState = ({
  state_code: stateCode,
  state_names: stateNames = [],
} = {}) => ({
  label: (stateNames.find(({ language }) => language === 'en') || {}).name || stateCode,
  value: stateCode,
});

export const fromReturnRequestSetting = ({
  allowed_days_for_return: allowedDaysForReturn,
  default_dropoff_locations: defaultDropoffLocations = {},
}) => ({
  allowedDaysForReturn,
  defaultDropoffLocations: mapValues(
    defaultDropoffLocations || {},
    (dropoff) => camelCaseKeys(dropoff),
  ),
});

export const toReturnRequestSetting = ({
  allowedDaysForReturn,
  defaultDropoffLocations = {},
}) => ({
  allowed_days_for_return: allowedDaysForReturn,
  default_dropoff_locations: mapValues(
    defaultDropoffLocations || {},
    (dropoff) => snakeCaseKeys(dropoff),
  ),
});
