import React from 'react';
import Spacer from './Spacer';
import Typography from './Typography';
import cls from '../helpers/cls';
import birdIllustration from '../assets/images/branding/bird-decorated-large.png';
import logo from '../assets/images/carriyo-logo-dark.svg';
import './PosterView.scss';

const ContentContainer = ({
  variant,
  description,
  title,
}) => (
  <div className="PosterView-container">
    {variant === 'standalone' && <img alt="carriyo-logo" className="PosterView-logo" src={logo} />}
    <Typography size="2xl" weight="medium">{title}</Typography>
    <Spacer y="2xl" />
    {description}
    <img alt="illustration" className="PosterView-illustration" src={birdIllustration} />
  </div>
);
const PosterView = (props) => {
  const {
    Poster,
    header = '',
    footer = '',
    variant = 'standalone',
    type = '',
  } = props;

  return (
    type === 'smb'
      ? (
        <ContentContainer
          variant={variant}
          title={header}
          description={footer}
        />
      )
      : (
        <div className={cls('PosterView', { variant })}>
          <Typography align="center" className="PosterView-header" variant="h3" nowrap={false}>
            {header}
          </Typography>
          <Poster className="PosterView-poster" />
          <div className="PosterView-footer">
            {footer}
          </div>
        </div>
      )
  );
};

export default PosterView;
