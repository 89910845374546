import { WebAuth } from 'auth0-js';
import config from './config';

const auth0Client = new WebAuth({
  domain: config.auth0.authDomain,
  clientID: config.auth0.clientId,
  redirectUri: window.location.origin,
  scope: 'openid profile email user_metadata app_metadata',
  audience: config.backendBaseUrl,
  responseType: 'token id_token',
});

export default auth0Client;
