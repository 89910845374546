import cookies from 'js-cookie';
import { get } from 'lodash';
import config from './config';

const tldDomain = window.location.hostname.split('.').slice(-2).join('.'); // carriyo.com or localhost

export function getCookie(key = tldDomain, domain = tldDomain) {
  return get(
    JSON.parse(cookies.get(key, { domain }) || '{}'),
    [config.env],
  );
}

export function setCookie(partial, key = tldDomain, domain = tldDomain) {
  const cookieValue = JSON.parse(cookies.get(key, { domain }) || '{}');
  cookies.set(
    key,
    JSON.stringify({
      ...cookieValue,
      [config.env]: {
        ...(cookieValue[config.env] || {}),
        ...partial,
      },
    }),
    { domain },
  );
}
