import { useEffect } from 'react';

function useAsyncEffect(
  asyncEffect,
  dependencies = [],
) {
  return useEffect(() => {
    asyncEffect();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, dependencies);
}

export default useAsyncEffect;
