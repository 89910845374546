import { some, every } from 'lodash';

// permissions
const CREATE = 'create';
const CLONE = 'clone';
const EDIT = 'edit';
const DELETE = 'delete';
const REPROCESS = 'reprocess';
const REASSIGN = 'reassign';
const RETRY = 'retry';
const UPDATE_STATUS = 'update status';
const CONFIRM = 'confirm';
const CANCEL = 'cancel';
const CHANGE_SEQUENCE = 'change sequence';
const RESET_PASSWORD = 'reset password';

// permission groups
// -------------------

// data entities
const CUSTOMER = 'CUSTOMER';
const NONE = 'NONE';
const SHIPMENT = 'SHIPMENT';

export const permissionGroups = {
  CUSTOMER,
  NONE,
  SHIPMENT,
};

export const canCreate = (permissions = []) => permissions.includes(CREATE);
export const canClone = (permissions = []) => permissions.includes(CLONE);
export const canEdit = (permissions = []) => permissions.includes(EDIT);
export const canUpdateStatus = (permissions = []) => permissions.includes(UPDATE_STATUS);
export const canChangeSequence = (permissions = []) => permissions.includes(CHANGE_SEQUENCE);
export const canReprocess = (permissions = []) => permissions.includes(REPROCESS);
export const canReassign = (permissions = []) => permissions.includes(REASSIGN);
export const canRetry = (permissions = []) => permissions.includes(RETRY);
export const canCancel = (permissions = []) => permissions.includes(CANCEL);
export const canConfirm = (permissions = []) => permissions.includes(CONFIRM);
export const canResetPassword = (permissions = []) => permissions.includes(RESET_PASSWORD);
export const canDelete = (permissions = []) => permissions.includes(DELETE);

const baseCustomerPermissions = [CREATE, CLONE, EDIT, DELETE];
const baseShipmentPermissions = [
  CREATE,
  CLONE,
  EDIT,
  REPROCESS,
  RETRY,
  REASSIGN,
  UPDATE_STATUS,
  CONFIRM,
  CANCEL,
];

// role permissions
// route id (as defined in routes.jsx) -> permission
const operatorPermissions = {
  [SHIPMENT]: baseShipmentPermissions,
};

const managerPermissions = {
  ...operatorPermissions,
};

const accountManagerPermissions = {
  ...managerPermissions,
};

const adminPermissions = {
  ...accountManagerPermissions,
  [CUSTOMER]: baseCustomerPermissions,
};

const superAdminPermissions = {
  ...adminPermissions,
};

const roleBasedPermissions = {
  operator: operatorPermissions,
  manager: managerPermissions,
  'account-manager': accountManagerPermissions,
  admin: adminPermissions,
  owner: adminPermissions,
  'super-admin': superAdminPermissions,
};

export function getPermissionsForRoute({ permissionGroup }, auth = {}) {
  const { role } = auth;
  let permissions = roleBasedPermissions[role]?.[permissionGroup] || [];

  if (typeof permissions === 'function') {
    permissions = permissions();
  }

  return {
    canCreate: canCreate(permissions),
    canClone: canClone(permissions),
    canEdit: canEdit(permissions),
    canUpdateStatus: canUpdateStatus(permissions),
    canChangeSequence: canChangeSequence(permissions),
    canReprocess: canReprocess(permissions),
    canReassign: canReassign(permissions),
    canRetry: canRetry(permissions),
    canCancel: canCancel(permissions),
    canConfirm: canConfirm(permissions),
    canResetPassword: canResetPassword(permissions),
    canDelete: canDelete(permissions),
  };
}

export const getPermissionsForGroup = (
  permissionGroup,
  auth,
) => getPermissionsForRoute({ permissionGroup }, auth);

export const isAnyPermitted = some;

export const isAllPermitted = every;
