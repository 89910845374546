const lookup = {
  add: 'added',
  cancel: 'cancelled',
  dismiss: 'dismissed',
  reset: 'reset',
  send: 'sent',
  ship: 'shipped',
  upload: 'uploaded',
};

const pastTense = (word) => {
  const cached = lookup[word];
  if (cached) return cached;
  const suffix = `${word.at(-1)}ed`.replace(/ee/, 'e');
  return `${word.slice(0, -1)}${suffix}`;
};

export default pastTense;
