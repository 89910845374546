import './polyfills';

import React from 'react';
import ReactDOM from 'react-dom';
import { Router as ReactRouter } from 'react-router-dom';
import { history } from './store';
import Router from './components/router/Router';
import GlobalInitializer from './components/global/Initializer';
import './logger';
import './index.scss';

function App() {
  return (
    <>
      <GlobalInitializer />
      <ReactRouter history={history}>
        <Router />
      </ReactRouter>
    </>
  );
}

ReactDOM.render(<App />, document.getElementById('root'));
