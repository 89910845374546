import React, { forwardRef } from 'react';
import cls from '../helpers/cls';
import './Linear.css';

const Linear = forwardRef((props, ref) => {
  const {
    children,
    className: classes,

    align = 'start',
    justify = 'start',

    flex,
    gap,
    orientation,
    padding,
    reverse,

    height,
    width,

    wrap,

    ...rest
  } = props;
  const className = cls('Linear', {
    align,
    justify,

    flex,
    gap,
    orientation,
    padding,
    reverse,

    height,
    width,

    wrap,
  }, classes);
  return (
    <div className={className} {...rest} ref={ref}>
      {children}
    </div>
  );
});

export default Linear;
