import { updateToast } from '../store';

const DEFAULT_HIDE_DURATION = 5000;

export const setToastState = updateToast;

export const error = (
  message,
  autoHideDuration = DEFAULT_HIDE_DURATION,
) => updateToast({
  show: true,
  icon: 'error',
  message,
  autoHideDuration,
  progress: false,
});

export const success = (
  message,
  autoHideDuration = DEFAULT_HIDE_DURATION,
) => updateToast({
  show: true,
  icon: 'success',
  message,
  autoHideDuration,
  progress: false,
});

export const info = (
  message,
  autoHideDuration = DEFAULT_HIDE_DURATION,
  progress,
  onClose,
) => updateToast({
  show: true,
  icon: 'info',
  message,
  autoHideDuration,
  progress,
  onClose,
});

export const warning = (
  message,
  autoHideDuration = DEFAULT_HIDE_DURATION,
  progress,
) => updateToast({
  show: true,
  icon: 'warning',
  message,
  autoHideDuration,
  progress,
});

export const close = () => updateToast({
  show: false,
  icon: '',
  message: '',
  progress: false,
  onClose: undefined,
  autoHideDuration: DEFAULT_HIDE_DURATION,
});
