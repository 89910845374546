import { castArray, intersection, uniq } from 'lodash';

// for enums (like carriers, delivery types etc), if allowed merchants is null,
// undefined or [], it means all merchants are allowed else return intersection
//
// This is different from how fetching of the enums itself works
// with fetch end-point, if allowed merchants is null or undefined it means all merchants
// are allowed. if allowed merchants is [], it means no carrier is allowed
// and finally if allowed merchants is array of strings, then intersection of them is what's allowed
/**
 * @param {{merchants: string[]|null}[]} enumList
 * @param {string[]|null} merchantsToFilter
 */
function filterEnumListByMerchants(enumList, merchantsToFilter) {
  const filterAccountLevelEnumsOnly = merchantsToFilter === null || merchantsToFilter?.[0] === null;
  // eslint-disable-next-line no-param-reassign
  const uniqMerchantsForFilter = uniq(castArray(merchantsToFilter).filter(Boolean));
  return enumList.filter(({ merchants }) => {
    if (filterAccountLevelEnumsOnly) return !merchants;
    if (!merchants?.length) return true;
    return intersection(merchants, uniqMerchantsForFilter).length > 0;
  });
}

export default filterEnumListByMerchants;
