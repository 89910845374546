/* eslint-disable react/no-unstable-nested-components */
import React, { useState, useEffect, useRef } from 'react';
import {
  difference,
  get,
  keyBy,
  set,
  uniqBy,
} from 'lodash';

import Autocomplete from '@material-ui/lab/Autocomplete';
import {
  Chip,
  TextField,
  Tooltip,

  createMuiTheme,
  ThemeProvider,
  makeStyles,
} from '@material-ui/core';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import cls from 'lib-frontend-shared/src/helpers/cls';
import sanitizeTextForRegex from 'lib-frontend-shared/src/helpers/sanitizeTextForRegex';
import naturalSort from 'lib-frontend-shared/src/helpers/naturalSort';
import textSearcher from 'lib-frontend-shared/src/helpers/textSearcher';
import useDeepMemo from 'lib-frontend-shared/src/helpers/useDeepMemo';
import './MaterialAutocomplete.scss';

const filterAndSortOptions = (options, searchText, additionalFieldsToSearch = []) => {
  const baseField = 'label';
  const fieldsToSearchBy = [baseField, ...additionalFieldsToSearch];
  const sanitizedSearchText = sanitizeTextForRegex(searchText);
  const getSearchRank = textSearcher(sanitizedSearchText);
  const enrichedFilteredOptions = options.map((option) => {
    const searchRanks = fieldsToSearchBy.map((field) => getSearchRank(option[field]));
    return [option, Math.max(...searchRanks)];
  }).filter(([, rank]) => rank);
  return enrichedFilteredOptions
    .sort(naturalSort.by(baseField)) // always sort by baseField first
    .sort((x, y) => y[1] - x[1])
    .map(([option]) => option);
};

const setDefault = (object, path, defaultValue) => {
  if (get(object, path) === undefined) {
    set(object, path, defaultValue);
  }
};
const defaultPopupIcon = <KeyboardArrowDownIcon fontSize="small" />;
function MaterialAutocomplete(props) {
  const {
    variant = 'compact',
    className = '',
    optionListClassName = '',
    options: optionsProp = [],
    clearable = false,
    value = '',
    freeSolo = true,
    isRequired = false,
    disabled = false,
    onChange = () => {},
    placeholder,
    customOptionProps: {
      chipTooltipTitle = '%label is a custom option',
    } = {},
    textFieldProps = {},
    validate = true,
    onInputChange: onParentInputChange = () => {},
    useLazyLoadOptions = false,
    extraFilteringEnums = [],
    noOptionsText,
    isLoading = false,
    loadingText = '',
    RenderComponent,
    onReset: onExtReset = () => {},
    showCustomOption = false,
    customValueLabel = '',
    transformLabel = ({ label }) => label,
    transformValue = ({ value: val }) => val,
    autoFocus = false,
    width = variant === 'compact' ? 'full' : 'auto',
    style,
    popupIcon = defaultPopupIcon,
    ...autocompleteProps
  } = props;

  // options need better change-detection so as to prevent unnecessary re-renders
  const options = useDeepMemo(optionsProp);

  // deep defaults
  // Note: can't use _.merge or _.cloneDeep as it messes with react refs in the props.
  setDefault(textFieldProps, 'style.width', '100%');

  const { multiple = false } = autocompleteProps;
  const internalRef = useRef();
  const externalRef = textFieldProps.ref;
  const ref = externalRef || internalRef;

  const [searchText, setSearchText] = useState('');
  const [autocompleteOptions, setAutocompleteOptions] = useState([]);
  const [selectedOptions, setSelectedOptions] = useState(multiple ? [] : { label: '', value: '' });

  const noSelection = multiple ? !selectedOptions?.length : !selectedOptions?.value;
  const customOption = showCustomOption
    ? { label: customValueLabel, value: customValueLabel }
    : null;
  const trimAndSetAutoComplete = (allOptions) => {
    let newOptions = allOptions;
    if (useLazyLoadOptions) {
      newOptions = [
        ...newOptions,
        { label: newOptions.length ? '...type to load more options' : 'No results found', disabled: true, value: '' },
      ];
    } else if (newOptions.length > 101) {
      newOptions = [
        ...newOptions.slice(0, 100),
        { label: '...type to show more options', disabled: true, value: '' },
      ];
    }
    setAutocompleteOptions([customOption, ...newOptions].filter(Boolean));
  };

  useEffect(() => {
    let newSelectedOptions = multiple ? [] : { label: '', value: '' }; // default option
    let fieldValue = value;
    // #region - setting values
    if (showCustomOption && fieldValue === customValueLabel) {
      setSelectedOptions(multiple ? [] : { label: '', value: '' });
      return;
    }
    if (fieldValue !== undefined) {
      if (multiple) {
        // 'multiple' option needs value to be an array
        // of strings or objects (with value & optional label)
        if (!Array.isArray(fieldValue)) {
          fieldValue = [fieldValue];
        }
        if (fieldValue.length) {
          const optionsLookUp = keyBy(options, 'value');
          newSelectedOptions = fieldValue.map((item) => {
            const optionValue = (typeof item === 'object' ? item.value : item) || '';
            const label = (typeof item === 'object' ? item.label : item) || optionValue;

            // find existing options
            if (optionsLookUp[optionValue]) return optionsLookUp[optionValue];
            return {
              label,
              value: optionValue,
              isACustomValue: true,
            };
          });
        }
      } else {
        // if none of the available options is selected
        newSelectedOptions = options.find(({ value: optionValue = '' }) => fieldValue === optionValue) || {
          label: fieldValue,
          value: fieldValue,
        };
      }
    }
    const updatedSearchText = !fieldValue && !useLazyLoadOptions ? '' : searchText;
    setSearchText(updatedSearchText);
    trimAndSetAutoComplete(
      difference(
        filterAndSortOptions(options, updatedSearchText, extraFilteringEnums),
        newSelectedOptions,
      ),
    );
    setSelectedOptions(newSelectedOptions);
    // #endregion
  }, [options, value]);

  const checkValidation = (autocompleteValue) => {
    if (!validate) return;
    // find input and remove invalidation
    if (isRequired && ref) {
      const inputEl = ref.current.querySelector('input[type="text"]');
      if (autocompleteValue) {
        inputEl.setCustomValidity('');
      } else {
        inputEl.setCustomValidity('Please fill out this field');
      }
    }
  };

  const onOptionSelect = (event, selected) => {
    let autocompleteValue;
    if (multiple) {
      // selected is an array
      autocompleteValue = selected?.length ? uniqBy(selected, 'value') : [];
      // sort and transform values to uppercase and use appropriate label
      autocompleteValue = autocompleteValue.map((option) => {
        const {
          useValueAsLabel = false,
          isACustomValue = false,
          value: mappedValue,
          label,
        } = option;
        if (isACustomValue) {
          return {
            value: mappedValue.toUpperCase(),
            label: useValueAsLabel ? mappedValue : label,
          };
        }
        return option;
      });
      checkValidation(autocompleteValue.length);
    } else {
      autocompleteValue = get(selected, 'value');
      checkValidation(autocompleteValue);
    }
    setSearchText('');
    return onChange(autocompleteValue);
  };

  // limit search feature to fewer entries
  const onInputChange = async (event, text, reason) => {
    // this event fires during initialization which causes an infinite loop of setState() calls
    if (!event) {
      return;
    }
    if (((!text || text === selectedOptions?.value) && reason === 'reset') || text === customValueLabel) {
      onExtReset();
      setSearchText('');
      trimAndSetAutoComplete(options);
      return;
    }
    setSearchText(text);
    if (!text || reason === 'reset') {
      trimAndSetAutoComplete(options);
      return;
    }
    await onParentInputChange(text);
    let newOptions = [];
    if (multiple) {
      newOptions = filterAndSortOptions(
        difference(options, selectedOptions),
        text,
        extraFilteringEnums,
      );
      if (!newOptions.length) {
        newOptions = [
          {
            value: text,
            label: `Add "${text}"`,
            // flag to know that this is a custom value
            isACustomValue: true,
            // flag to know when to use label and
            // when to use uppercased value
            useValueAsLabel: true,
          },
        ];
      }
    } else {
      checkValidation(text);
      newOptions = filterAndSortOptions(options, text, extraFilteringEnums);
    }

    trimAndSetAutoComplete(newOptions);
  };

  // for free text, single select accept text as value on blur
  if (freeSolo && !multiple) {
    autocompleteProps.onBlur = (event) => {
      const text = event.target.value;
      if (text) {
        // this reduce is super slow for some reason. so mutate accumulator
        const optionsByPhrase = options.reduce((acc, item) => {
          acc[item.label.toLowerCase()] = item;
          acc[item.value.toLowerCase()] = item;
          return acc;
        }, {});

        const option = optionsByPhrase[text.toLowerCase().trim()] || {
          label: text.trim(),
          value: text.trim(),
          isACustomValue: true,
        };

        onChange(option.value);
      }
    };
  }

  if (multiple) {
    autocompleteProps.renderTags = (tagValues, getTagProps) => {
      const theme = createMuiTheme({
        palette: {
          secondary: {
            main: '#FCC6B1',
            contrastText: '#ED4F4F',
          },
        },
        typography: {
          fontFamily: '"Inter", "Noto Naskh Arabic", "Arial", sans-serif',
          fontColor: '#14919B',
        },
        overrides: {
          MuiChip: {
            root: {
              height: '30px',
            },
          },
        },
      });
      return (
        <ThemeProvider theme={theme}>
          {tagValues.map(({ label, isACustomValue = false }, index) => {
            const { key, ...restOfTheTagProps } = getTagProps({ index });
            if (isACustomValue) {
              return (
                <Tooltip key={key} title={chipTooltipTitle.replace('%label', label)}>
                  <Chip color="secondary" label={label} {...restOfTheTagProps} />
                </Tooltip>
              );
            }

            return <Chip label={label} {...getTagProps({ index })} />;
          })}
        </ThemeProvider>
      );
    };

    textFieldProps.onPaste = (event) => {
      event.preventDefault();
      const pastedText = event.clipboardData.getData('Text');
      const pastedPhrases = pastedText.split(/[\n,]/g);

      // this reduce is super slow for some reason. so mutate accumulator
      const optionsByPhrase = options.reduce((acc, item) => {
        acc[item.label.toLowerCase()] = item;
        acc[item.value.toLowerCase()] = item;
        return acc;
      }, {});

      const convertedOptions = uniqBy(
        [
          ...selectedOptions,
          ...pastedPhrases
            .filter((text) => text.trim())
            .map(
              (text) => optionsByPhrase[text.toLowerCase().trim()] || ({
                label: text.trim(),
                value: text.trim(),
                isACustomValue: true,
              }),
            ),
        ],
        'value',
      );
      onChange(convertedOptions);
    };
  }

  const autocompleteClasses = makeStyles({
    inputRoot: {
      minHeight: '38px', // keep in sync with MaterialReactSelect (in multiselect mode)
    },
    listbox: {
      maxHeight: 'min(300px, 30vh) !important',
    },
    endAdornment: {
      top: '50%',
      transform: 'translateY(-50%)',
    },
    clearIndicator: {
      marginRight: '0',
    },
    popupIndicator: {
      marginRight: '0',
    },
  })();
  const autocompleteComponentWidth = { full: '100%', long: '250px', short: '175px' };

  return (
    <Autocomplete
      autoHighlight
      className={cls('MaterialAutocomplete', { variant }, className)}
      style={{
        width: autocompleteComponentWidth[width] || width,
        ...style,
      }}
      classes={{
        ...autocompleteClasses,
        paper: cls('MaterialAutocomplete-optionPaper', { variant }),
        listbox: cls('MaterialAutocomplete-optionList', { variant, hasCustomOption: showCustomOption }, optionListClassName),
      }}
      freeSolo={freeSolo}
      disabled={disabled}
      options={autocompleteOptions}
      noOptionsText={noOptionsText}
      // MUI is not filtering out all the results if we try to search based on
      // the non-label enum. This func. should place here to get the right results.
      filterOptions={(allOptions) => allOptions}
      getOptionLabel={({ label: optionLabel = '' } = {}) => optionLabel}
      getOptionSelected={({ value: autocompleteValue = '' } = {}, selectedValue = '') => autocompleteValue === selectedValue}
      getOptionDisabled={({ disabled: isOptionDisabled }) => isOptionDisabled}
      onInputChange={onInputChange}
      loading={isLoading}
      loadingText={loadingText}
      value={selectedOptions}
      disableClearable={!clearable}
      popupIcon={popupIcon}
      renderInput={(params) => {
        const inputPropsValue = get(params, 'inputProps.value');
        return (
          <TextField
            {...params}
            ref={ref}
            placeholder={noSelection ? placeholder : undefined}
            {...textFieldProps}
            InputProps={{
              ...params.InputProps,
              ...(textFieldProps?.InputProps || {}),
            }}
            // eslint-disable-next-line react/jsx-no-duplicate-props
            inputProps={{
              ...params.inputProps,
              value: transformValue({
                value: useLazyLoadOptions
                  ? (searchText || (showCustomOption && inputPropsValue === customValueLabel ? '' : inputPropsValue))
                  : inputPropsValue,
                selectedOptions,
              }),
              ...(textFieldProps?.inputProps || {}),
              autoFocus,
              autoComplete: 'disabled', // disable autocomplete and autofill
            }}
          />
        );
      }}
      renderOption={({
        type,
        label: optionLabel = '',
        value: optionValue,
        ...restProps
      }) => {
        if (RenderComponent) {
          return <RenderComponent {...restProps} label={optionLabel} value={optionValue} />;
        }
        return transformLabel({ label: optionLabel, ...restProps });
      }}
      onChange={onOptionSelect}
      {...(autocompleteProps || {})}
    />
  );
}

export default MaterialAutocomplete;
