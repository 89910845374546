/* eslint-disable react/no-unstable-nested-components */
// -- react, router -- //
import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { Redirect, Route, Switch } from 'react-router-dom';

// -- custom --
import { isEmpty } from 'lodash';
import useGlobalStates from '../../helpers/useGlobalStates';
import Layout from '../layout/Layout';
import routes from './routes';
import { getPermissionsForRoute } from '../../permissions';
import redirects from './redirects';
import * as routeActions from '../../actions/route';
import { setTenantId } from '../../actions/auth';
import PageNotFound from '../layout/PageNotFound';

function RouteComponent(props) {
  const {
    route, match, match: { params = {} } = {}, location: { pathname, search } = {},
  } = props;
  const existingOrUpdatedTenantId = params?.tenantId;
  const {
    auth,
    auth: { accessByTenantId, tenantId },
  } = useGlobalStates(['auth']);

  const [noAccessOrInvalidTenant, setNoAccessOrInvalidTenant] = useState(false);
  const permissions = getPermissionsForRoute(route, auth);
  const accessibleTenantList = Object.keys(accessByTenantId);

  useEffect(() => {
    routeActions.setRoute({
      ...route,
      permissions,
      breadcrumbs: route?.breadcrumbs || [],
      match,
    });
  }, [JSON.stringify(permissions)]);

  // Tenant Validation and Redirection
  useEffect(() => {
    if (!pathname || isEmpty(accessByTenantId)) return;
    if (!pathname.startsWith('/tenants/')) {
      let updatedTenantId = tenantId?.toUpperCase();
      const tenantIds = Object.keys(accessByTenantId || {});
      if (!updatedTenantId && tenantIds === 1) {
        const [firstTenantID] = tenantIds.sort();
        updatedTenantId = firstTenantID;
      }
      if (updatedTenantId) {
        routeActions.replace(`/tenants/${updatedTenantId}${pathname !== '/' ? `${pathname}${search}` : ''}`, false);
      } else {
        routeActions.replace('/tenants/', false);
      }
    } else if (pathname.startsWith('/tenants/')) {
      setNoAccessOrInvalidTenant(false);
      const hasAccessAndValidTenantId = accessibleTenantList.includes(existingOrUpdatedTenantId);
      if (hasAccessAndValidTenantId) {
        if (existingOrUpdatedTenantId !== tenantId) {
          setTenantId(existingOrUpdatedTenantId, true);
        }
      } else {
        setNoAccessOrInvalidTenant(true);
      }
    }
  }, [pathname, tenantId]);

  const hasValidPathname = pathname && (pathname.startsWith('/tenants/') || pathname === '/');

  return (
    <>
      <Helmet>
        <title>{['Store App', tenantId ? route.title : null].filter(Boolean).join(' | ')}</title>
      </Helmet>
      {hasValidPathname ? (
        <Layout
          {...props}
          component={!noAccessOrInvalidTenant ? route.Component : PageNotFound}
          mobileComponent={!noAccessOrInvalidTenant ? route.MobileComponent : PageNotFound}
          route={{
            ...route,
            permissions,
          }}
        />
      ) : null}
    </>
  );
}

function Router() {
  return (
    <Switch>
      {routes.map((route) => (
        <Route
          key={route.path}
          exact
          path={route.path}
          component={(props) => <RouteComponent route={route} {...props} />}
        />
      ))}
      {redirects.map(({ id, path, to }) => (
        <Route
          key={`redirects-${id}`}
          exact
          path={path}
        >
          <Redirect to={to} />
        </Route>
      ))}
    </Switch>
  );
}

export default Router;
