// this snakeCase is not same as lodash.snakeCase.
// e.g address1 would be converted to address_1 by lodash
// whereas address1 would remain address1 with this one.

const snakeCase = (key) => key
  .replace(/.[A-Z]+/g, (m) => m.split('').join('_'))
  .toLowerCase();

const dateRegex = /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}\.\d{3}.*?Z$/;
// e.g. date format 2021-04-05T18:00:00.000Z

/**
 * @param {GenericObject} obj
 * @return {GenericObject}
 * @template GenericObject
 */
function snakeCaseKeys(obj) {
  if (!obj || typeof obj !== 'object') {
    return obj;
  }
  if (Array.isArray(obj)) {
    return obj.map(snakeCaseKeys);
  }
  return Object.fromEntries(
    Object.entries(obj).map(([key, value]) => {
      // don't snake case dates.
      if (dateRegex.test(key)) {
        return [key, snakeCaseKeys(value)];
      }
      return [snakeCase(key), snakeCaseKeys(value)];
    }),
  );
}

export default snakeCaseKeys;
