/* eslint-disable camelcase */
import {
  camelCase,
  find,
  snakeCase,
  sortBy,
  startCase,
  uniqBy,
} from 'lodash';

import naturalSort from 'lib-frontend-shared/src/helpers/naturalSort';
import countryData from 'lib-frontend-shared/src/enums/countries.json';
import locales from 'lib-frontend-shared/src/enums/locales.json';

import config from './config';

export const CLICKNSHIP_TENANT_ID = 'CLICKNSHIP';

const localeOptions = Object
  .entries(locales)
  .map(([value, label]) => ({ value, label }));

export const gcc_country_codes = ['AE', 'SA', 'KW', 'OM', 'BH', 'QA'];
const countriesWithMandatoryState = ['CA', 'US'];
export const isStateMandatory = (country) => countriesWithMandatoryState.includes(country);
export const countries = countryData
  .map(({ code, name }) => ({ label: name.long, value: code }));

export const SHARED_MERCHANT_ID = 'ACCOUNT';

export const countryListWithPhonePrefixLabel = countryData.map(({ code, name, phone }) => ({
  label: `${name.long} (+${phone.prefix})`, value: code,
}));

export const countryToTimezoneLookup = Object.fromEntries(
  countryData.map(({ code, timezone }) => [code, timezone]),
);

const sortedRawCountries = sortBy(countryData, 'name.long');
export const countryInfo = sortedRawCountries.map(({
  code, flag, name, phone, currency: { code: currencyCode = '' } = {},
}) => ({
  code,
  flag,
  name: name.long,
  phonePrefix: `+${phone.prefix}`,
  phoneSample: phone.sample,
  currency: currencyCode,
}));

export const currencies = uniqBy(
  countryData
    .filter(({ currency: { code } }) => code)
    .map(({ currency: { code, name } }) => ({
      label: `${code} - ${name}`, value: code,
    }))
    .sort(naturalSort.by('label')),
  'label',
);

const status = [{
  label: 'Booked',
  value: 'booked',
}, {
  label: 'Ready to Ship',
  value: 'ready_to_ship',
}, {
  label: 'Shipped',
  value: 'shipped',
}, {
  label: 'In Transit',
  value: 'in_transit',
}, {
  label: 'Out for Delivery',
  value: 'out_for_delivery',
}, {
  label: 'Awaiting Customer Collection',
  value: 'awaiting_customer_collection',
}, {
  label: 'Delivered',
  value: 'delivered',
}, {
  label: 'Delivery Confirmed',
  value: 'delivery_confirmed',
}, {
  label: 'Failed Collection Attempt',
  value: 'failed_collection_attempt',
}, {
  label: 'Failed Delivery Attempt',
  value: 'failed_delivery_attempt',
}, {
  label: 'Ready for Return',
  value: 'ready_for_return',
}, {
  label: 'Return in Transit',
  value: 'return_in_transit',
}, {
  label: 'Returned',
  value: 'returned',
}, {
  label: 'Return Confirmed',
  value: 'return_confirmed',
}, {
  label: 'Cancelled',
  value: 'cancelled',
}, {
  label: 'Suspended',
  value: 'suspended',
}, {
  label: 'Missing',
  value: 'missing',
}, {
  label: 'Delayed',
  value: 'delayed',
}, {
  label: 'Error',
  value: 'error',
}, {
  label: 'Pending',
  value: 'pending',
}, {
  label: 'Draft',
  value: 'draft',
}];

const errorTypes = [{
  value: 'TECHNICAL',
  label: 'Technical',
}, {
  value: 'VALIDATION',
  label: 'Validation',
}, {
  value: 'UNKNOWN',
  label: 'Unknown',
}];

const errorSources = [{
  value: 'CARRIYO',
  label: 'Carriyo',
}, {
  value: 'CARRIER',
  label: 'Carrier',
}];

const errorCodes = [
  'required_data_missing',
  'required_data_invalid',
  'no_carrier_assigned',
  'payment_method_invalid',
  'coordinates_invalid',
  'coordinates_latitude_invalid',
  'coordinates_longitude_invalid',
  'label_format_invalid',
  'weight_unit_invalid',
  'dimension_unit_invalid',
  'country_invalid',
  'email_invalid',
  'phone_number_invalid',
  'carrier_account_invalid',
  'partner_location_invalid',
  'time_slot_invalid',
  'custom_attribute_invalid',
  'custom_attribute_value_invalid',
  'parcel_item_invalid',
  'email_missing',
  'merchant_missing',
  'default_merchant_not_configured',
  'payment_currency_invalid',
  'postcode_missing',
  'item_origin_country_missing',
  'item_hscode_missing',
  'item_weight_missing',
  'parcel_info_missing',
  'parcel_weight_missing',
  'parcel_dimensions_missing',
].sort().map((code) => ({
  value: code,
  label: startCase(code),
}));

const errorTriggers = [{
  value: 'BOOKING',
  label: 'Booking',
}, {
  value: 'SCHEDULING',
  label: 'Scheduling',
}];

const forwardShipmentCommsTriggers = [
  'shipped',
  'out_for_delivery',
  'delivered',
  'failed_delivery_attempt',
  'returned',
  'missing',
  'delayed',
];
const reverseShipmentCommsTriggers = [
  'ready_to_ship',
  'shipped',
  'failed_collection_attempt',
  'delivered',
];

export const statuses = status.map((item) => ({
  ...item,
  notify: forwardShipmentCommsTriggers.includes(item.value),
}));

export const reverseShipmentStatuses = status.map((item) => ({
  ...item,
  notify: reverseShipmentCommsTriggers.includes(item.value),
}));

export const Status = {
  deleted: 'DELETED',
  active: 'ACTIVE',
  inactive: 'INACTIVE',
  pending: 'PENDING',
  trouble: 'TROUBLE',
};

const manifestStatus = [{
  label: 'Pending',
  value: 'pending',
}, {
  label: 'Ready to Ship',
  value: 'ready_to_ship',
}, {
  label: 'Shipped',
  value: 'shipped',
}, {
  label: 'Cancelled',
  value: 'cancelled',
}, {
  label: 'Error',
  value: 'error',
}];
export const manifestStatuses = manifestStatus;

const returnRequestStatus = [{
  label: 'Pending',
  value: 'pending',
  notify: true,
  milestone: false,
}, {
  label: 'Approved',
  value: 'approved',
  notify: true,
}, {
  label: 'Completed',
  value: 'completed',
  notify: true,
}, {
  label: 'Rejected',
  value: 'rejected',
  notify: true,
}, {
  label: 'Cancelled',
  value: 'cancelled',
  notify: true,
  milestone: false,
}];
export const returnRequestStatuses = returnRequestStatus;
export const returnRequestMilestones = returnRequestStatus.filter(
  ({ milestone = true }) => milestone,
);

const milestones = [{
  label: 'Booked',
  value: 'booked',
}, {
  label: 'Shipped',
  value: 'shipped',
}, {
  label: 'Delivered',
  value: 'delivered',
}, {
  label: 'Returned',
  value: 'returned',
}];

const payment_mode = [{
  label: 'Pre Paid',
  value: 'PRE_PAID',
}, {
  label: 'Cash on Delivery',
  value: 'CASH_ON_DELIVERY',
}];
export const paymentModes = payment_mode;

export const languages = [
  {
    label: 'Arabic',
    value: 'ar',
  },
  {
    label: 'English',
    value: 'en',
  },
];

export const webhookTypes = [{
  label: 'Forward Shipment',
  value: 'SHIPMENT',
}, {
  label: 'Reverse Shipment',
  value: 'REVERSE_SHIPMENT',
}, {
  label: 'Return Requests',
  value: 'RETURN_REQUEST',
}];

export const sourceTypes = [{
  label: 'Carriyo API',
  value: 'api',
}, {
  label: 'Carriyo Dashboard',
  value: 'dashboard',
}, {
  label: 'Carriyo Store App',
  value: 'storeapp',
}, {
  label: 'Shopify Connector',
  value: 'shopify_connector',
}, {
  label: 'WooCommerce Connector',
  value: 'woocommerce_connector',
}, {
  label: 'Magento Connector',
  value: 'magento_connector',
}];

// FIXME: make this non-exported and for internal use,
// rather each enum must be exported. This makes refactoring easier
export const lookup = {
  countries,
  currencies,
  status: statuses,
  statuses, // alias
  reverseShipmentStatus: reverseShipmentStatuses,
  returnRequestStatus,
  manifestStatus,
  payment_mode,
  milestones,
  languages,
  locales: localeOptions,
  webhookTypes,
  errorTypes,
  errorSources,
  errorCodes,
  errorTriggers,
};

export const getLabelFromValue = ({ enumName, value }) => {
  const { label = value } = find(
    lookup[snakeCase(enumName)] || lookup[camelCase(enumName)],
    ['value', value],
  ) || {};
  return label;
};

export const getShortNameForCountry = (code) => {
  const { abbreviation = code } = find(countries, ['code', code]) || {};
  return abbreviation;
};

const adminRole = 'admin';

export const roles = [adminRole, 'account-manager', 'manager', 'operator', 'viewer'];

const ownerRole = 'owner';

export const ownerOrAboves = [ownerRole, 'super-admin'];

const adminOrAboves = [
  ...roles.filter((value) => value === adminRole),
  ...ownerOrAboves,
];

export const isAdminOrAbove = (role) => adminOrAboves.some(
  (value) => role === value,
);

export const isAccountOwner = (role) => role === ownerRole;

export const catalogImagePlaceholder = `//${config.trackingAppShortDomain}/static/pages/assets/placeholder.png`;
