import { keyBy } from 'lodash';
import hashFunc from 'fnv1a';

import camelCaseKeys from 'lib-frontend-shared/src/helpers/camelCaseKeys';
import snakeCaseKeys from 'lib-frontend-shared/src/helpers/snakeCaseKeys';
import moment from '../helpers/moment';
import {
  carriyoClient,
  nodeMiddlewareClient,

  // utils
  getBackendHeadersToAdd,
  getCacheBustHeaders,
} from './base';
import { getStates } from '../store';
import {
  fromCustomAttribute,
  fromDeliveryType,
  toDeliveryType,
  toCustomAttribute,
} from '../api-transforms';

export const getUserDefinedEnums = async ({
  props,
  bustCache,
}) => {
  const {
    auth: {
      tenantId,
      carriyoUserId,
      merchants: authMerchants,
    },
  } = getStates();

  const { data } = await nodeMiddlewareClient.get('/enums', {
    headers: {
      ...getBackendHeadersToAdd(),
      'user-tz': moment.tz.guess(),
      ...(bustCache ? getCacheBustHeaders() : {}),
    },
    params: {
      props,
      // cache busting
      v: hashFunc([
        tenantId,
        carriyoUserId,
        JSON.stringify(authMerchants),
        'v2',
      ].filter(Boolean).join('-')),
    },
  });

  const {
    tenantSettings,
    merchants, // all accessible merchants
    carrierMetadata,
    deliveryTypes,
    customAttributes,
  } = data;
  return {
    tenantSettings: camelCaseKeys(tenantSettings),
    merchants,
    carrierMetadata,
    deliveryTypes: deliveryTypes.map(fromDeliveryType),
    customAttributes: customAttributes
      .filter((item) => item.attribute_entity_type === 'SHIPMENT')
      .map(fromCustomAttribute),
    addressCustomAttributes: customAttributes
      .filter((item) => item.attribute_entity_type === 'ADDRESS')
      .map(fromCustomAttribute),
  };
};

// STATES
export const getStateList = async (params) => {
  const { auth: { tenantId } } = getStates();
  const { data = [] } = await carriyoClient.get('/states', {
    params: {
      ...params,
      cache: 'true',
    },
    headers: {
      'tenant-id': tenantId,
    },
  });
  return data;
};

// CITIES
export const getCityList = async (params) => {
  const { auth: { tenantId } } = getStates();
  const { data = [] } = await carriyoClient.get('/cities', {
    params: {
      ...params,
      cache: 'true',
    },
    headers: {
      'tenant-id': tenantId,
    },
  });
  return data;
};

// AREAS

export const getAreaList = async (params) => {
  const { data = [] } = await carriyoClient.get('/areas', {
    params,
  });
  return camelCaseKeys(data);
};

export const getCitiesWithState = async (params) => {
  const [cities, states] = await Promise.all([
    getCityList(params),
    getStateList(params),
  ]);
  const stateMap = keyBy(states, 'state_code');
  const enrichedCities = cities.map(({ state_code: state, ...rest }) => ({
    ...stateMap[state], ...rest,
  }));
  return [enrichedCities, states];
};

// MERCHANTS

export const getMerchants = async ({ bustCache, tenantId } = {}) => {
  const { auth: { tenantId: currentTenantId, carriyoUserId } } = getStates();
  const { data } = await carriyoClient.get('/merchants', {
    params: {
      // cache group
      v: hashFunc([
        tenantId || currentTenantId,
        carriyoUserId,
      ].filter(Boolean).join('-')),
    },
    headers: {
      ...(tenantId ? { 'tenant-id': tenantId } : {}),
      ...(bustCache ? getCacheBustHeaders() : {}),
    },
  });
  return camelCaseKeys(data);
};

export const addMerchant = async (payload) => {
  const { data } = await carriyoClient.post('/merchants', snakeCaseKeys(payload));
  return data;
};

export const updateMerchants = async (payload) => {
  const { data } = await carriyoClient.put('/merchants', snakeCaseKeys(payload));
  return data;
};

// CUSTOM ATTRIBUTES

export const addCustomAttribute = async (payload) => {
  const { data } = await carriyoClient.post('/attributes', toCustomAttribute(payload));
  return toCustomAttribute(data);
};

export const updateCustomAttribute = async (payload) => {
  const { data } = await carriyoClient.put(`/attributes/${payload.customAttributeId}`, toCustomAttribute(payload));
  return toCustomAttribute(data);
};

export const deleteCustomAttribute = async ({ customAttributeId }) => {
  const { data } = await carriyoClient.delete(`/attributes/${customAttributeId}`);
  return toCustomAttribute(data);
};

// DELIVERY TYPES

export const addDeliveryType = async (payload) => {
  const { data } = await carriyoClient.post('/delivery-types', toDeliveryType(payload));
  return toDeliveryType(data);
};

export const deleteDeliveryType = async (deliveryTypeId) => {
  const { data } = await carriyoClient.delete(`/delivery-types/${deliveryTypeId}`, { data: {} });
  return data;
};

// Address Model

export const getAddressModels = async (params = {}) => {
  const {
    auth: {
      tenantId,
    },
  } = getStates();
  const { data } = await carriyoClient.get('/address-models', {
    params: {
      ...params,
      v: hashFunc(tenantId),
    },
  });
  return camelCaseKeys(data);
};
