// initial states can be fetched multiple times. Hence always return new object,
// so that mutations to arrays etc, will not affect future fetches.
export default () => ({
  // 'global' contains states used across most pages
  global: {
    globalEnumsLoaded: false,
    tenantSettings: {},
    merchants: [],
    merchantIds: [],
    selectedMerchants: [],
    selectedMerchantIds: [],

    // account level access flags
    canAccessAccountNotificationSettings: false,
    canAccessAccountRoutingRules: false,
    canAccessAccountServiceLevels: false,

    // enums
    carrierMetadata: [],

    deliveryTypes: [],
    deliveryTypesForAccount: [],
    allDeliveryTypes: [],

    reasonCodes: [],

    selectedLocation: undefined,
    selectedLocationId: undefined,

    customAttributes: [],
    customAttributesForAccount: [],
    allCustomAttributes: [],

    addressModelByCountry: null,
  },
  // other shared states or shared component states
  cities: {},
  states: {},
  auth: {
    authorizing: true,
    user: {},
    role: '',
    merchants: [],
    accessByTenantId: {},
  },
  dialogToDisplay: '',
  loading: 0,
  toast: {
    showToast: false,
    icon: '',
    message: '',
  },
  route: {
    permissions: {},
    breadcrumbs: [],
  },
  cachedEntities: {
    locations: [],
    carriers: [],
  },

  // page specific states (mostly)
  carrier: {},
  carrierCapacity: {},
  clicknShipService: {
    allServices: [],
    filters: {},
    actionCallTriggered: false,
  },
  communicationSettings: {
    smsSettings: {},
    emailSettings: {},
    whatsappSettings: {},
  },
  shipmentCommunicationSettings: {
    smsSettings: {},
    smsTriggers: {},
    smsTemplateOverrides: {},

    emailSettings: {},
    emailTriggers: {},
    emailTemplateOverrides: {},

    whatsappSettings: {},
    whatsappTriggers: {},
  },
  reverseShipmentCommunicationSettings: {
    smsSettings: {},
    smsTriggers: {},
    smsTemplateOverrides: {},

    emailSettings: {},
    emailTriggers: {},
    emailTemplateOverrides: {},

    whatsappSettings: {},
    whatsappTriggers: {},
  },
  returnRequestCommunicationSettings: {
    smsSettings: {},
    smsTriggers: {},
    smsTemplateOverrides: {},

    emailSettings: {},
    emailTriggers: {},
    emailTemplateOverrides: {},

    whatsappSettings: {},
    whatsappTriggers: {},
  },
  location: {},
  locationsList: {
    locations: [],
  },
  network: {},
  manifest: {
    rowsPerPageOptions: [10, 25, 50],
    totalRows: 0,
  },
  manifestList: {
    rowsPerPageOptions: [10, 25, 50],
    totalRows: 0,
    manifests: [],
  },
  reports: {
    serviceLevels: {},
    carrierPerformance: {},
    shipmentHistory: {},
    transitTime: {},
    ageingShipments: {},
    errorHistory: {},
    regionalAnalysis: {},
    communications: { meta: {} },
    feedbacks: {
      totalRatings: 0,
      averageRating: 0,
      ratingsBreakdown: [
        {
          rating: 5,
          count: 0,
        }, {
          rating: 4,
          count: 0,
        }, {
          rating: 3,
          count: 0,
        }, {
          rating: 2,
          count: 0,
        }, {
          rating: 1,
          count: 0,
        },
      ],
      positiveReasonsBreakdown: [],
      negativeReasonsBreakdown: [],
    },
  },
  returnRequestDetail: {},
  returnRequestList: {
    rowsPerPageOptions: [10, 25, 50],
    totalRows: 0,
    returnRequests: [],
  },
  returnRequestSettings: {},
  reverseShipment: {
    steps: [],
    products: [],
    parcels: [],
  },
  reverseShipmentList: {
    rowsPerPageOptions: [10, 25, 50],
    totalRows: 0,
    shipments: [],
    multiSelectedShipmentIds: [],
    multiSelectOperationData: {},
  },
  reverseShipmentRuleList: {
    searchString: '',
    sortBy: 'priority',
    sortDirection: 'asc',
    defaultRulesSortBy: 'priority',
    defaultRulesSortDirection: 'asc',
    rules: [],
    ruleIdToDelete: null,

    filters: {
      active: true,
    },

    visibleColumnIds: {
      checkbox: 1,
      priority: 1,
      name: 1,
      description: 0,
      schedule: 1,
      dropoff: 0,
      pickup: 0,
      deliveryTypes: 0,
      paymentTypes: 0,
      orderLimit: 0,
      orderValue: 0,
      carrier: 1,
      active: 1,
      actions: 1,
      carrierAccount: 1,
    },
  },
  rule: {
    name: '',
    description: '',
    priority: '',
    source: {
      selectedCountries: [],
      selectedCities: [],
      selectedLocations: [],
    },
    destination: {
      selectedCountries: [],
      selectedCities: [],
      selectedLocations: [],
    },
    carrier: '',
    orderLimit: {},
    orderValue: {
      from: 0,
      to: 100000,
    },
    deliveryTypes: [],
    paymentTypes: [],
    conditions: [],
    active: true,
    days: [],
    startTime: '00:00',
    endTime: '00:00',
  },
  ruleList: {
    searchString: '',
    sortBy: 'priority',
    sortDirection: 'asc',
    defaultRulesSortBy: 'priority',
    defaultRulesSortDirection: 'asc',
    rules: [],
    ruleIdToDelete: null,

    filters: {
      active: true,
    },

    visibleColumnIds: {
      checkbox: 1,
      priority: 1,
      name: 1,
      description: 0,
      schedule: 1,
      dropoff: 0,
      pickup: 0,
      deliveryTypes: 0,
      paymentTypes: 0,
      orderLimit: 0,
      orderValue: 0,
      carrier: 1,
      active: 1,
      actions: 1,
      carrierAccount: 1,
    },
  },
  serviceLevel: {},
  serviceLevelsSettings: {
    configs: [],
    activeTab: 'FULFILLMENT',

    visibleReverseLevelsColumnIds: {
      sequence: 1,
      name: 1,
      time: 1,
      sourceCountries: 0,
      destinationCountries: 0,
      carrierAccounts: 0,
      deliveryTypes: 0,
      leadTime: 1,
      active: 1,
      actions: 1,
    },
    visibleForwardLevelsColumnIds: {
      sequence: 1,
      name: 1,
      time: 1,
      sourceCountries: 0,
      destinationCountries: 0,
      carrierAccounts: 0,
      deliveryTypes: 0,
      leadTime: 1,
      active: 1,
      actions: 1,
    },
  },
  shipmentList: {
    rowsPerPageOptions: [10, 25, 50],
    totalRows: 0,
    shipments: [],
    multiSelectedShipmentIds: [],
    multiSelectOperationData: {},
    uploadList: [],
    uploadListResults: [],
  },
  shipment: {
    steps: [],
    products: [],
    parcels: [],
  },
  tenantSettings: {
    tenantId: null,
    tenantName: null,
    defaultCountry: null,
    defaultCurrency: null,
    defaultMerchant: null,
    merchants: {},
    sortBy: 'id',
    sortDirection: 'asc',
  },
  userList: {
    rowsPerPageOptions: [10, 25, 50],
    pageSize: 10,
    page: 0,
    total: 0,
    sortBy: 'created_at',
    sortDirection: 'desc',
    hideInternalUsers: false,
    users: [],
  },
  webhookSettings: {
    settings: [],
    selectedWebhook: {
      status: 'ACTIVE',
      configName: '',
      url: '',
      headers: [
        { key: 'Content-Type', value: 'application/json' },
      ],
      entityType: 'SHIPMENT',
      notifyStatus: [],
      notifyLabelUpdate: false,
      notifyCarriyoLabelUpdate: false,
      conditions: [],
    },
  },

  // Store app specific states
  customerList: {
    rowsPerPageOptions: [10, 25, 50],
    totalRows: 0,
    customers: [],
  },
  customerDetail: {
    addresses: [],
  },
  orderList: {
    rowsPerPageOptions: [10, 25, 50],
    totalRows: 0,
    shipments: [],
  },
});
