import moment from './moment';
import getTimezone from './getTimezone';

export const formats = {
  SHORT_FORMAT_WITH_MINUTES: 'MMM D, hh:mm a',
  LONG_FORMAT_WITH_MINUTES: 'D MMM YYYY hh:mm a',
  LONG_FORMAT_WITH_SECONDS: 'D MMM YYYY hh:mm:ss a',
};

/**
 * @param {string} iso8601Timestamp
 * @param {string} format
 * @param {undefined | boolean | string} [timezone]
 */
const formatDate = (
  iso8601Timestamp,
  format = formats.LONG_FORMAT_WITH_MINUTES,
  timezoneOrCountry, // true => show local zone in formatting
  timezoneSuffix = false,
  showTimezoneLabel = true,
) => {
  if (!iso8601Timestamp) return '';
  const date = moment(iso8601Timestamp);
  if (!date.isValid()) return '';
  const zone = getTimezone(timezoneOrCountry);
  const dateText = date.tz(zone).format(format);
  return timezoneOrCountry && showTimezoneLabel
    ? `${dateText} (${zone}${timezoneSuffix ? ' time' : ''})`
    : dateText;
};

export const formatTimeWithSeconds = (date) => formatDate(
  date,
  formats.LONG_FORMAT_WITH_SECONDS,
);

export const shortFormatDateWithMinutes = (date) => formatDate(
  date,
  formats.SHORT_FORMAT_WITH_MINUTES,
);

export default formatDate;
