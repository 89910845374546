import React from 'react';
import { castArray, flatten } from 'lodash';

// -- material ui icons --
import CheckCircleIcon from '@material-ui/icons/CheckCircleOutline';
import CloseIcon from '@material-ui/icons/CloseOutlined';
import ErrorIcon from '@material-ui/icons/ErrorOutline';
import WarningIcon from '@material-ui/icons/WarningOutlined';

// -- material ui --
import IconButton from '@material-ui/core/IconButton';
import InfoIcon from '@material-ui/icons/Info';
import Snackbar from '@material-ui/core/Snackbar';
import SnackbarContent from '@material-ui/core/SnackbarContent';
import LinearProgress from '@material-ui/core/LinearProgress';

import Typography from 'lib-frontend-shared/src/components/Typography';

import { useGlobalStates } from '../store';
import * as toastActions from '../actions/toast';
import './Toast.scss';

const icons = {
  success: CheckCircleIcon,
  warning: WarningIcon,
  error: ErrorIcon,
  info: InfoIcon,
};

function Toast() {
  const {
    toast: {
      show,
      icon,
      message,
      autoHideDuration,
      progress,
      onClose: onExtClose = () => {},
    },
  } = useGlobalStates(['toast']);
  if (!icon) {
    return null;
  }

  const Icon = icons[icon];

  return (
    <Snackbar
      className="Toast"
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'right',
      }}
      open={show}
      autoHideDuration={autoHideDuration === Infinity ? null : autoHideDuration}
      onClose={(event, reason) => {
        if (reason !== 'clickaway') {
          toastActions.close();
        }
      }}
    >
      <SnackbarContent
        className={`Toast Toast-${icon}`}
        aria-describedby="client-snackbar"
        message={(
          <div className="Toast-message">
            <Icon fontSize="medium" className="Toast-icon" />
            <div>
              {flatten(castArray(message)).map((msg) => (
                <React.Fragment key={msg}>
                  <Typography variant="para.xs">
                    {typeof msg === 'object' ? JSON.stringify(msg) : String(msg)}
                  </Typography>
                </React.Fragment>
              ))}
            </div>
            {progress && <LinearProgress style={{ width: '100px', marginLeft: '5px' }} />}
          </div>
        )}
        action={[
          <IconButton
            key="close"
            aria-label="Close"
            color="inherit"
            onClick={() => {
              onExtClose();
              toastActions.close();
            }}
          >
            <CloseIcon fontSize="medium" className="Toast-iconColor" />
          </IconButton>,
        ]}
      />
    </Snackbar>
  );
}

export default Toast;
