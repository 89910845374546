import React, { forwardRef } from 'react';
import {
  mapValues, omit, pick, reduce, uniq,
} from 'lodash';
import cls from '../helpers/cls';

import './Typography.css';

const configs = {
  '::stub': {
    // All params here for reference (also used by styleAttribs())
    align: false, // center | left | right
    color: 'secondary', // canvas | disabled | error | normal | primary | secondary | tertiary
    font: 'header', // header | normal
    inline: false, // Boolean
    italic: false, // Boolean
    letterSpacing: '3xs', // 3xl | 2xl | xl | lg | md | sm | xs | 2xs | 3xs
    lineHeight: 'xs', // xl | lg | md | sm | xs
    nowrap: false, // Boolean
    size: '2xl', // 3xl | 2xl | xl | lg | md | sm | xs | 2xs | 3xs
    tag: 'stub', // <stub> is the tag (derived from variant-id if not found as variant.config.tag)
    transform: 'auto', // auto | none | upper | lower
    underline: false, // Boolean
    weight: 'medium', // light | normal | medium | semibold | bold
  },
  ...mapValues({
    h1: {
      letterSpacing: '3xs',
      lineHeight: 'xs',
      size: '3xl',
      weight: 'medium',
    },
    h2: {
      letterSpacing: '2xs',
      lineHeight: 'sm',
      size: '2xl',
      weight: 'bold',
    },
    h3: {
      letterSpacing: 'xl',
      lineHeight: 'md',
      size: 'xl',
      weight: 'medium',
    },
    h4: {
      letterSpacing: 'md',
      lineHeight: 'lg',
      size: 'lg',
      weight: 'medium',
    },
  }, (props) => ({
    color: 'normal',
    font: 'normal',
    nowrap: true,
    ...props,
  })),
  ...reduce({
    'para.xl': {
      lineHeight: 'xl',
      size: 'lg',
    },
    'para.lg': {
      lineHeight: 'xl',
      size: 'md',
    },
    'para.md': {
      lineHeight: 'md',
      size: 'sm',
    },
    'para.sm': {
      lineHeight: 'md',
      size: 'xs',
    },
    'para.xs': {
      lineHeight: 'xl',
      size: '2xs',
    },
  }, (acc, props, key) => {
    const config = {
      font: 'normal',
      letterSpacing: 'md',
      tag: 'div',
      weight: 'normal',
      ...props,
    };
    acc[key] = { ...config, color: 'normal' };
    acc[`${key}:body`] = { ...config, color: 'description' };
    return acc;
  }, {}),
  ...reduce({
    'para.xl': {
      lineHeight: 'xl',
      nowrap: true,
      size: '3xl',
      weight: 'bold',
      tag: 'h1',
    },
    'para.lg': {
      lineHeight: 'xl',
      nowrap: true,
      size: 'lg',
      tag: 'h4',
    },
    'para.md': {
      lineHeight: 'md',
      nowrap: true,
      size: 'md',
    },
    'para.sm': {
      lineHeight: 'md',
      size: 'sm',
    },
    'para.xs': {
      lineHeight: 'xl',
      size: 'xs',
      weight: 'semibold',
    },
  }, (acc, props, key) => {
    const config = {
      color: 'normal',
      font: 'normal',
      letterSpacing: 'md',
      tag: 'div',
      weight: 'medium',
      ...props,
    };
    acc[`${key}:head`] = config;
    return acc;
  }, {}),
};

const styleAttribs = uniq(Object.values(configs).reduce((acc, props) => [...acc, ...Object.keys(props)], []));

// FIXME: Start using the 'tag' prop and give up on fallbacks
const fallbackTag = (variant) => variant.split('.')[0];

const Typography = forwardRef((props, ref) => {
  const {
    children = null,
    className: classes,
    disabled = false,
    tag: overrideTag,
    variant = 'span',
    ...misc
  } = props;
  const miscProps = omit(misc, styleAttribs);
  const overrides = pick(misc, styleAttribs);
  const { tag: defaultTag, ...textProps } = configs[variant] || {};
  const className = cls('Typography', {
    ...textProps,
    ...overrides,
    ...(disabled ? { color: 'disabled' } : {}),
  }, classes);
  return React.createElement(
    overrideTag || defaultTag || fallbackTag(variant),
    { ...miscProps, className, ref },
    children,
  );
});

export default Typography;
