import React, { useState, useEffect } from 'react';
import {
  IconButton,
  createMuiTheme,
  ThemeProvider,
} from '@material-ui/core';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import { grey } from '@material-ui/core/colors';
import Tooltip from './Tooltip';
import mainTheme from '../theme';
import * as toastActions from '../actions/toast';

import './CopyToClipboardButton.scss';

function fallbackCopyTextToClipboard({ text, successCallback, errorCallback }) {
  const textArea = document.createElement('textarea');
  textArea.value = text;

  // Avoid scrolling to bottom
  textArea.style.top = '0';
  textArea.style.left = '0';
  textArea.style.position = 'fixed';

  document.body.appendChild(textArea);
  textArea.focus();
  textArea.select();

  try {
    const successful = document.execCommand('copy');
    if (successful) successCallback();
    else errorCallback();
  } catch (err) {
    console.error('Fallback Copy: Unable to copy', err);
    errorCallback();
  }

  document.body.removeChild(textArea);
}

function copyTextToClipboard({ text, successCallback, errorCallback }) {
  if (!navigator || !navigator.clipboard) {
    fallbackCopyTextToClipboard({
      text,
      successCallback,
      errorCallback,
    });
    return;
  }
  navigator.clipboard.writeText(text).then(successCallback, errorCallback);
}

/**
 * @param {object} param
 * @param {string[]|function} param.source
 * @param {string} [param.copyText] shown in tooltip
 * @param {string} [param.copiedText] shown in toaster
 * @param {"small"|"default"} [param.size="small"]
 * @param {"grey"|"primary"} [param.color="grey"]
 */
const CopyToClipboardButton = ({
  source = [],
  className,
  copyText = 'Copy to Clipboard',
  copiedText = 'Copied',
  disabled = false,
  size = 'small',
  color = 'grey',
  noPadding = false,
  Icon = FileCopyIcon,
  iconStyle,
  style,
}) => {
  const [textCopied, setTextCopied] = useState(false);

  useEffect(() => {
    setTextCopied(false);
    return () => setTextCopied(false);
  }, [source]);

  useEffect(() => {
    if (!textCopied) return () => {};
    const timeout = setTimeout(() => {
      setTextCopied(false);
    }, 5000);

    return () => clearTimeout(timeout);
  }, [textCopied]);

  const theme = createMuiTheme({
    palette: {
      primary: mainTheme.palette.primary,
      secondary: {
        main: grey[500],
      },
    },
  });

  const copyToClipboard = async (event) => {
    event.stopPropagation();
    if (textCopied) return;
    let textToBeCopied;
    if (typeof source === 'function') {
      textToBeCopied = (await source()).join('\n');
    } else {
      textToBeCopied = source.join('\n');
    }
    copyTextToClipboard({
      text: textToBeCopied,
      successCallback: () => {
        setTextCopied(true);
        toastActions.info(copiedText);
      },
      errorCallback: () => {
        setTextCopied(false);
      },
    });
  };

  if (!source || !(source.length || typeof source === 'function')) {
    return null;
  }

  return (
    <ThemeProvider theme={theme}>
      <Tooltip
        className={noPadding ? 'CopyToClipboardButton-noPadding' : ''}
        title={copyText}
      >
        <IconButton
          color={color === 'grey' ? 'secondary' : 'primary'}
          disabled={disabled}
          size={['default', 'medium'].includes(size) ? 'medium' : 'small'}
          onClick={copyToClipboard}
          style={style}
        >
          <Icon
            className={className}
            fontSize={size === 'default' ? 'default' : 'small'}
            color="inherit"
            style={iconStyle}
          />
        </IconButton>
      </Tooltip>
    </ThemeProvider>
  );
};

export default CopyToClipboardButton;
