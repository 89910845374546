const getSegments = (text) => (
  text.toLowerCase().trim().match(/\p{L}+|\p{N}+/gu)?.filter(Boolean) || []
);

const textSearcher = (pattern = '') => {
  const searchWords = getSegments(pattern);
  const fullSegment = searchWords.join(' ');
  return searchWords.length ? (sourceText = '') => {
    const words = getSegments(sourceText);
    const hasPerfectMatch = words.join(' ') === fullSegment;
    if (hasPerfectMatch) return 7;
    const hasExactMatch = words.some((segment, index) => (
      segment === searchWords[0]
      && words.slice(index, index + searchWords.length).join(' ') === fullSegment
    ));
    if (hasExactMatch) return 5;
    const hasPhrasePrefixMatch = words.some((segment, index) => (
      (
        searchWords.length === 1
        && segment.startsWith(searchWords[0])
      ) || (
        segment === searchWords[0]
        && words.slice(index).join(' ').startsWith(fullSegment)
      )
    ));
    if (hasPhrasePrefixMatch) return 3;
    const uniqueWords = [...new Set(words)];
    const uniqueMatches = uniqueWords.filter(
      (word) => searchWords.includes(word),
    );
    return uniqueMatches.length / uniqueWords.length;
  } : () => 1;
};

export default textSearcher;
