export default function sanitizeTextForRegex(rawText) {
  return rawText
    .replace(/\\/g, '\\\\')
    .replace(/\[/g, '\\[')
    .replace(/\^/g, '\\^')
    .replace(/\./g, '\\.')
    .replace(/\|/g, '\\|')
    .replace(/\?/g, '\\?')
    .replace(/\*/g, '\\*')
    .replace(/\+/g, '\\+')
    .replace(/\(/g, '\\(')
    .replace(/\)/g, '\\)');
}
