import { capitalize, identity } from 'lodash';
import pastTense from 'lib-frontend-shared/src/helpers/pastTense';
import getErrorFromBEResponse from './getErrorFromBEResponse';
import * as loadingActions from '../actions/loading';
import * as toastActions from '../actions/toast';

const isIgnorable = (error) => ['AbortError', 'CanceledError'].includes(error.name);

/**
 * @template T
 * @param {string} entity
 * @param {(...any) => Promise<T>} func
 * @param {object} options
 * @param {string} [options.operation='load']
 * @param {((error: string) => any)|string} [options.onFailure='Unable to load <entity>']
 */
export const reader = (entity, func, options = {}) => async (...args) => {
  const {
    ignore = { result: undefined, status: [] },
    operation = 'load',
    onFailure = `Unable to ${operation} ${entity}`,
    onFinally = identity,
  } = options;
  loadingActions.showLoadingState();
  try {
    const data = await func(...args);
    return { data };
  } catch (error) {
    if (!isIgnorable(error)) {
      const message = getErrorFromBEResponse(error);
      const { response: { status } = {} } = error;
      if (ignore.status.includes(status)) {
        const { result } = ignore;
        const data = typeof result === 'function'
          ? result(error)
          : result;
        return { data };
      }
      if (onFailure) {
        if (typeof onFailure === 'string') {
          toastActions.error([onFailure, message]);
        } else {
          await onFailure(message, error);
        }
      }
    }
    return { error };
  } finally {
    onFinally();
    loadingActions.hideLoadingState();
  }
};

/**
 * @template T
 * @param {string} entity
 * @param {(...any) => Promise<T>} func
 * @param {object} options
 * @param {string} [options.operation='load']
 * @param {((result: T, ...any) => any)|string} [options.onSuccess='Saved <entity> successfully.']
 * @param {((error: string) => any)|string} [options.onFailure='Unable to save <entity>"']
 */
export const writer = (entity, func, options = {}) => async (...args) => {
  const {
    operation = 'save',
    onSuccess = `${capitalize(pastTense(operation))} ${entity} successfully.`,
    onFailure = `Unable to ${operation} ${entity}`,
    onFinally = identity,
  } = options;
  loadingActions.showLoadingState();
  try {
    const result = await func(...args);
    if (onSuccess) {
      if (typeof onSuccess === 'string') {
        toastActions.success(onSuccess);
      } else {
        await onSuccess(result, ...args);
      }
    }
    return { data: result };
  } catch (error) {
    if (!isIgnorable(error)) {
      const message = getErrorFromBEResponse(error);
      if (onFailure) {
        if (typeof onFailure === 'string') {
          toastActions.error([onFailure, message]);
        } else {
          await onFailure(message, error);
        }
      }
    }
    return { error };
  } finally {
    onFinally();
    loadingActions.hideLoadingState();
  }
};
