/* eslint-disable react/button-has-type */

import React from 'react';
import cls from '../helpers/cls';

import CircularProgress from './CircularProgress';

import './FormButton.css';

const State = ['disabled', 'ready', 'waiting'].reduce((acc, key) => ({ ...acc, [key]: key }), {});

const FormButton = (props) => {
  const {
    className: classes,
    color = 'primary',
    size = 'md',
    children,
    disabled = false,
    state = disabled ? State.disabled : State.ready,
    type = 'button',
    width,
    ...rest
  } = props;
  const className = cls('FormButton', {
    color, size, state, width,
  }, classes);
  return (
    <button
      {...rest}
      className={className}
      disabled={state !== State.ready}
      type={type}
    >
      {state === State.waiting && (
        <CircularProgress className="FormButton-spinner" color="canvas" size={20} />
      )}
      <div className="FormButton-content">
        {children}
      </div>
    </button>
  );
};

export default FormButton;
