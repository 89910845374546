import originalAxios from 'axios';

const methods = ['request', 'get', 'post', 'put', 'patch', 'delete', 'head', 'options'];
const patched = (action) => async (...args) => {
  // axios's default stack trace is super useless
  const { stack } = new Error();
  const requestUserTime = (new Date()).toISOString();
  try {
    return await action(...args);
  } catch (error) {
    // @ts-ignore
    error.stack = stack;
    error.requestUserTime = requestUserTime;
    error.responseUserTime = (new Date()).toISOString();
    throw error;
  }
};

/**
 * @param {import('axios').AxiosStatic} axios
 * @returns {import('axios').AxiosStatic}
 */
const enhanceErrorStack = (axios) => {
  const proxy = patched(axios);
  Object.setPrototypeOf(proxy, axios);
  for (const method of methods) { // eslint-disable-line no-restricted-syntax
    proxy[method] = patched((...args) => axios[method](...args));
  }
  // @ts-ignore
  proxy.create = (...args) => enhanceErrorStack(axios.create(...args));
  // @ts-ignore
  return proxy;
};

window.axios = originalAxios;

export default enhanceErrorStack(originalAxios);
