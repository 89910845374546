import { findIndex, get, set } from 'lodash';
import moment from '../helpers/moment';
import { getStates, updateCachedEntities } from '../store';

const upsertEntity = ({
  entity = '',
  list = [],
  identifier = '',
}) => {
  const {
    cachedEntities,
  } = getStates();
  const collection = get(cachedEntities, entity, []);

  list.forEach((data) => {
    const newDataIdentifier = get(data, identifier, '');
    const index = findIndex(collection, { [identifier]: newDataIdentifier });
    const ttl = moment().add(15, 'minutes').unix();
    if (index > -1) {
      set(collection, index, {
        ...data,
        ttl,
      });
    } else {
      collection.push({
        ...data,
        ttl,
      });
    }
  });
  updateCachedEntities({
    ...cachedEntities,
    [entity]: collection,
  });
  return { [entity]: collection };
};

const fetchNonExistingIds = ({
  identifiers,
  entity,
  identifier,
}) => {
  const {
    cachedEntities,
  } = getStates();
  const collection = get(cachedEntities, entity, []);

  const idsToBeFetched = [];
  identifiers.reduce((acc, id) => {
    const index = findIndex(collection, { [identifier]: id });
    if (index < 0) {
      idsToBeFetched.push(id);
    }
    const { ttl: savedDataTTL } = get(collection, index, {});
    const currentTimeStamp = moment().unix();
    const isDataOutdated = currentTimeStamp > savedDataTTL;
    if (isDataOutdated) {
      idsToBeFetched.push(id);
    }
    return acc;
  }, []);

  return idsToBeFetched;
};

export {
  upsertEntity,
  fetchNonExistingIds,
};
