import { createMuiTheme } from '@material-ui/core/styles';

const mobileMediaQuery = '@media (max-width: 760px)';

const theme = createMuiTheme({
  palette: {
    primary: {
      main: '#00063B',
      // light: will be calculated from palette.primary.main,
      light: '#00063B',
      // dark: will be calculated from palette.primary.main,
      dark: '#00063B',
      // contrastText: will be calculated to contrast with palette.primary.main
      contrastText: '#fff',
    },
    secondary: {
      main: '#112135',
      // dark: will be calculated from palette.secondary.main,
      light: '#3a485f',
      dark: '#111111',
      // contrastText: '#ffcc00',
    },
    error: {
      main: '#f44336',
      light: '#e57373',
      dark: '#d32f2f',
      contrastText: '#fff',
    },
    // Used by `getContrastText()` to maximize the contrast between
    // the background and the text.
    contrastThreshold: 4.5,
    // Used by the functions below to shift a color's luminance by approximately
    // two indexes within its tonal palette.
    // E.g., shift from Red 500 to Red 300 or Red 700.
    tonalOffset: 0.2,
  },
  typography: {
    fontFamily: '"Inter", "Noto Naskh Arabic", "Arial", sans-serif',
    fontColor: '#14919B',
    // fontWeightRegular: '400',

    h6: {
      fontFamily: '"Space Grotesk", "Noto Naskh Arabic"',
      fontSize: '14px',
      fontWeight: '500',
      marginTop: '0px',
      color: '#202020',
    },
    h5: {
      fontFamily: 'Space Grotesk',
      fontSize: '14px',
      fontWeight: '600',
      marginTop: '0px',
      color: '#202020',
    },
    h4: {
      fontFamily: 'Space Grotesk',
      fontSize: '16px',
      fontWeight: '500',
      marginTop: '0px',
      color: '#181818',
    },
    h3: {
      fontFamily: 'Space Grotesk',
      fontSize: '18px',
      fontWeight: '500',
      marginTop: '0px',
    },
    h2: {
      fontFamily: 'Space Grotesk',
      fontSize: '20px',
      fontWeight: '500',
    },
    h1: {
      fontFamily: 'Space Grotesk',
      fontSize: '24px',
      fontWeight: '600',
    },
    subtitle1: {
      fontSize: '13px',
      fontWeight: '400',
      marginTop: '0px',
      color: '#202020',
    },
    subtitle2: {
      fontSize: '12px',
      fontWeight: '400',
      marginTop: '0px',
      color: '#181818',
    },
    body1: {
      fontSize: '12px',
      fontWeight: '400',
      marginTop: '0px',
      color: '#616161',
    },
    body2: {
      fontSize: '12px',
      fontWeight: '400',
      marginTop: '0px',
      color: '#777777',
    },
    caption: {
      fontSize: '13px',
      fontWeight: '400',
      marginTop: '0px',
      color: '#616161',
    },
  },
  overrides: {
    MuiTooltip: {
      tooltip: {
        fontSize: '12px',
        textAlign: 'center',
      },
    },
    MuiSelect: {
      icon: {
        top: 'calc(50% - 11px)',
      },
    },
    MuiChip: {
      root: {
        fontSize: '12px',
        fontWeight: '500px',
        marginTop: '0px',
        marginBottom: '0px',
        height: '26px',
        minWidth: '75px',
      },
    },
    MuiInput: {
      input: {
        '&:out-of-range': {
          'border-bottom': '2px solid #f44336',
        },
      },
    },
    MuiFormLabel: {
      root: {
        fontSize: '13px',
        fontWeight: '400',
        marginTop: '0px',
        color: '#616161',
      },
    },
    MuiStepLabel: {
      label: {
        fontSize: '13px',
        fontWeight: '400',
      },
      active: {
        fontSize: '13px',
        fontWeight: '500',
      },
    },
    MuiInputLabel: {
      root: {
        fontSize: '13px',
        fontWeight: '400',
        marginTop: '0px',
        color: '#616161',
      },
    },
    MuiFormControlLabel: {
      root: {
        fontSize: '13px',
        fontWeight: '400',
        marginTop: '0px',
        color: '#616161',
      },
    },
    MuiLink: {
      root: {
        fontSize: '13px',
        fontWeight: '400',
        marginTop: '0px',
        color: '#00063B',
      },
      underlineNone: {
        '&:hover': {
          'text-decoration': 'none',
        },
        '&:focus': {
          'text-decoration': 'none',
        },
      },
    },
    MuiTablePagination: {
      caption: {
        fontSize: '13px',
        fontWeight: '400',
        marginTop: '0px',
        color: '#202020',
      },
      input: {
        fontSize: '13px',
      },
    },
    MuiButton: {
      root: {
        fontSize: '14px',
        fontWeight: '500',
        marginTop: '0px',
        padding: '0.5px 8px',
        minWidth: 'auto',
      },
      text: {
        padding: '0.5px 8px',
      },
      textPrimary: {
        '&:hover': {
          'background-color': '#242a56',
          color: 'white',
        },
        '&:active': {
          'background-color': '#242a56',
          color: 'white',
        },
      },
      textSecondary: {
        '&:hover': {
          'background-color': 'rgba(17, 33, 53, 0.4)',
        },
        '&:active': {
          'background-color': 'rgba(17, 33, 53, 0.5)',
        },
      },
      containedPrimary: {
        '&:hover': {
          'background-color': '#242a56',
        },
        '&:active': {
          'background-color': '#242a56',
        },
      },
      containedSecondary: {
        '&:hover': {
          'background-color': 'rgba(17, 33, 53, 0.6)',
        },
        '&:active': {
          'background-color': 'rgba(17, 33, 53, 0.4)',
        },
      },
      outlined: {
        padding: '0px 8px',
      },
      outlinedPrimary: {
        border: '1px solid #00063B',
        '&:hover': {
          'background-color': '#00063B',
          color: 'white',
        },
        '&:active': {
          'background-color': '#242a56',
          border: 'none',
          color: 'white',
        },
      },
      outlinedSecondary: {
        border: '1px solid #112135',
        '&:hover': {
          'background-color': '#112135',
          color: 'white',
        },
        '&:active': {
          'background-color': 'rgba(17, 33, 53, 0.4)',
          border: 'none',
          color: 'white',
        },
      },
    },
    MuiSvgIcon: {
      root: {
        width: '20px',
        height: '20px',
      },
      fontSizeSmall: {
        width: '15px',
        height: '15px',
      },
      fontSizeInherit: {
        width: '10px',
        height: '10px',
      },
    },
    MuiIcon: {
      root: {
        width: '20px',
        height: '20px',
      },
      fontSizeSmall: {
        width: '15px',
        height: '15px',
      },
      fontSizeInherit: {
        width: '10px',
        height: '10px',
      },
    },
    MuiBadge: {
      badge: {
        height: '15px',
        padding: '0 3px',
        fontSize: '10px',
        minWidth: '15px',
        borderRadius: '7px',
      },
      colorError: {
        backgroundColor: '#FB2661',
      },
      anchorOriginTopRightRectangle: {
        transform: 'scale(1) translate(100%, -50%)',
      },
    },
    MuiDialog: {
      scrollPaper: {
        [mobileMediaQuery]: {
          'align-items': 'flex-end',
        },
      },
    },
    paperScrollPaper: {
      [mobileMediaQuery]: {
        margin: 0,
        'border-radius': 0,
      },
    },
  },
});

export default theme;
