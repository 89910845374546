import React from 'react';
import cls from '../helpers/cls';
import './Spacer.css';

const Spacer = (props) => {
  const { flex, x, y } = props;
  const className = cls('Spacer', { flex, x, y });
  return <div aria-hidden="true" className={className} />;
};

export default Spacer;
