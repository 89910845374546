/* eslint-disable jsx-a11y/alt-text */
import React from 'react';
import cls from '../helpers/cls';
import './Sticker.css';

const Sticker = (props) => {
  const { src, className, ...rest } = props;
  return (
    <img className={cls('Sticker', {}, className)} src={src} style={rest} />
  );
};

export default Sticker;
