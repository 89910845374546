import { get } from 'lodash';

const naturalSort = Intl.Collator(undefined, {
  numeric: true, ignorePunctuation: true, sensitivity: 'accent',
}).compare;

const naturalSortBy = (field, desc) => (x, y) => {
  const xValue = get(x, field);
  const yValue = get(y, field);
  const params = desc ? [yValue, xValue] : [xValue, yValue];
  return naturalSort(...params);
};

naturalSort.by = naturalSortBy;

export default naturalSort;
